import React from "react";
import { connect } from "react-redux";

interface IPaginationBox {
    numberOfItems:any,
    itemsPerPage:any,
    pageNumberProps:any,
}

const PaginationBox = ({ numberOfItems, itemsPerPage, pageNumberProps }: IPaginationBox) => {

    const { pageNumber, setPageNumber } = pageNumberProps;

    const handlePrevious = () => {
        if (pageNumber > 0)
            setPageNumber(pageNumber - 1);
    }

    const handleNext = () => {
        if (pageNumber + 1 < countTotalPages())
            setPageNumber(pageNumber + 1);
    }

    const handlePageNumberClick = (e:any,pageNumber:number) => {
        setPageNumber(pageNumber);
    }

    const countTotalPages = () => {
        return Math.ceil(numberOfItems / itemsPerPage);
    }

    const createPagesList = () => {
        const totalPages = countTotalPages();
        let pagesArray = [];

        for (let i = 0; i < totalPages; i++) {
            pagesArray.push(<li key={i}><a onClick={(e:any)=>{handlePageNumberClick(e,i)}} className={pageNumber === i ? "active" : ""} href="#ph">{i + 1}</a></li>);
        }

        return pagesArray;
    }

    const pagesList = createPagesList();

    return (
        <div className="pagination-box">
            <ul>
                {pageNumber!==0?(<li className="pagination-first"><button onClick={handlePrevious} /></li>):(<li></li>)}
                {pagesList}
                {pageNumber!==(countTotalPages()-1)?(<li className="pagination-last"><button onClick={handleNext} /></li>):(<li></li>)}
            </ul>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(PaginationBox)