import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getFilteredListByIsShow, host, parseHTML } from "../../../utils/commonComponentUtils";
import CustomScrollBar from "../../CustomScrollBar";

interface ITeamPopUp {
    manageTeamProps: any,
    foldData: any,
    customObjectName: any
}

const TeamPopUp = ({ manageTeamProps, foldData, customObjectName }: ITeamPopUp) => {
    useEffect(()  => {
        document.body.classList.add('modal-open');
    
        return () => {
            document.body.classList.remove('modal-open');
        };
    });

    // use num to increase/decrease index
    const getEmployeeByIndex = (num: number = 0) => {
        try {
            return getFilteredListByIsShow(foldData)[manageTeamProps?.manageTeamPopUp?.selectedIndex + num][customObjectName];
        } catch (e) {
            return null;
        }
    }

    const handleClose = () => {
        manageTeamProps.setManageTeamPopUp({ ...manageTeamProps.manageTeamPopUp, isShow: false })
    }

    const handleNavigationClick = (num: number) => {
        let { manageTeamPopUp } = manageTeamProps;
        manageTeamProps.setManageTeamPopUp({ ...manageTeamPopUp, selectedIndex: manageTeamPopUp.selectedIndex + num })
    }

    if (manageTeamProps?.manageTeamPopUp?.isShow) {        
        const selectedEmployee = getEmployeeByIndex();
        const beforeEmployee = getEmployeeByIndex(-1);
        const afterEmployee = getEmployeeByIndex(1);
        return (
            <div>
            <div className="modal fade show " id="teamMembers" style={{ display: 'block', paddingRight: '17px' }}>
                <div className="modal-dialog teamMember__modal" role="document">
                    <div className="modal-content">
                        <div className="teammember_modal__inner">
                            <button type="button" className="close" >
                                <img onClick={handleClose} src="static/images/close-black-icon.svg" alt="" />
                            </button>
                            <div className="teammember__details">
                                <div className="teammember__img">
                                    <img src={host + selectedEmployee?.Image1?.url} alt={selectedEmployee?.Heading} />
                                </div>
                                <div className="teammember__content">
                                    <div className="teammember__info">
                                        <h2>{selectedEmployee?.Heading}</h2>
                                        <span>{selectedEmployee?.SubHeading}</span>
                                    </div>
                                 
                                        <div className="teammember__des">
                                        <CustomScrollBar>
                                         <div className="team-pop-height" >
                                           {parseHTML(selectedEmployee?.Description)}
                                        </div>
                                         </CustomScrollBar>
                                        </div>
                                   
                                </div>

                            </div>
                            <div className="member-pagination">
                                {beforeEmployee ? (<div className="prev_member">
                                    <button className="pagination-btns" onClick={() => { handleNavigationClick(-1) }}>
                                        <div className="member_pagi_img">
                                            <img src={host + beforeEmployee?.Image1?.url} alt={beforeEmployee?.Heading} />
                                        </div>
                                        <div className="member_pagi_info">
                                            <strong>{beforeEmployee?.Heading} </strong>
                                            <span>{beforeEmployee?.SubHeading}</span>
                                        </div>
                                    </button>
                                </div>) : (<></>)}

                                {afterEmployee ? (<div className="next_member">
                                    <button className="pagination-btns" onClick={() => { handleNavigationClick(1) }}>
                                        <div className="member_pagi_info">
                                            <strong>{afterEmployee?.Heading}</strong>
                                            <span>{afterEmployee?.SubHeading}</span>
                                        </div>
                                        <div className="member_pagi_img">
                                            <img src={host + afterEmployee?.Image1?.url} alt={afterEmployee?.Heading} />
                                        </div>
                                    </button>
                                </div>) : (<></>)}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show"></div>
            </div>
        )
    } else {
        return <></>
    }

}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(TeamPopUp)