/**
 * This list contains names of fold types in strapi
 */

export const BIG_PICTURE_COMPONENT = "BigPictureComponent"
export const FOUR_BLOCK_COMPONENT = "FourBlockSectionComponent"
export const SLIDER_COMPONENT = "SliderComponent"
export const THREE_BLOCK_COMPONENT = "ThreeBlockSectionComponent"

export const TAB_COMPONENT = "TabComponent"
export const BIG_PICTURE_CONSULTING_COMPONENT = "BigPictureConsultingComponent"
export const BANNER_COMPONENT = "BannerComponent"
export const HEADER_COMPONENT = "Header"
export const FOOTER_COMPONENT = "Footer"

export const BIG_PICTURE_INDUSTRY_COMPONENT = "BigPictureIndustryComponent"
export const TABS_INDUSTRY_COMPONENT = "TabsIndustryComponent"

export const CLIENT_MAJOR_COMPONENT="ClientMajorComponent"
export const CLIENT_DETAIL_COMPONENT="ClientDetailComponent"
export const ARTICLE_DETAIL_COMPONENT="ArticleDetailComponent"

export const CAREER_MAJOR_COMPONENT = "CareerMajorComponent"
export const CAREER_DETAIL_COMPONENT = "CareerDetailComponent"

export const BLOG_COMPONENT ="BlogComponent"
export const BLOG_DETAIL="BlogDetailComponent"

export const TEAM_BOX="TeamBoxComponent"
export const TEAM_BANNER="TeamBannerComponent"

export const LOCATION_MAP_COMPONENT="LocationComponent"
export const CONTACT_FORM_COMPONENT="ContactFormComponent"

export const PAGE_SEARCH_COMPONENT="PageSearchComponent"