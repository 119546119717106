import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { setShowSuccessMessage } from "../../../actions/uiAction";
import { postData } from "../../../actions/websiteAction";
import { BECOME_OUR_CUSTOMER } from "../../../constants/localStorage";
import { validate, validateOnSubmit } from "../../../utils/FormValidation/BecomeOurCustomerFormValidation";
import ErrorMessage from "../../Helper/ErrorMessage";

interface IBecomeOurCustomerForm {
    connectPostData: Function
    closeForm: Function,
    connectSetShowSuccessMessage: Function
}

const BecomeOurCustomerForm = ({ connectPostData,closeForm,connectSetShowSuccessMessage }: IBecomeOurCustomerForm) => {

    const initialState = {
        name: null,
        email: null,
        companyName: null,
        phone: "",
        logo: {} as any,
        info: ""
    }

    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState({} as any);    

    const handleChange = (e: any) => {
        const myValue = {
            ...values,
            [e.target.name]: e.target.value
        };
        setValues(myValue);
        setErrors(validate(myValue));
    }

    const handleFile = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.files[0]
        })
    }


    const handleSubmit = (e: any) => {
        let myErrors = validateOnSubmit(values);
        setErrors(myErrors);
        if (_.isEmpty(myErrors) && values !== initialState) {
            const data = {
                "Name": values.name,
                "Email": values.email,
                "CompanyName": values.companyName,
                "Phone": values.phone,
                "Description": values.info,
                "published_at": null
            }

            const formData = new FormData();
            formData.append('data', JSON.stringify(data));

            if (values.logo && values.logo.name)
                formData.append(`files.CompanyLogo`, values.logo, values.logo?.name);

            connectPostData({ url: "/become-our-customer-cots", data: formData }, (res:any) => {
                setValues(initialState);
                connectSetShowSuccessMessage(true);              
                localStorage.setItem(BECOME_OUR_CUSTOMER,JSON.stringify(res.data));
                closeForm();
            });
        }
    }

    

    const handleReset = (e: any) => {
        setValues(initialState);
        setErrors({});
    }

    return (
        <>
            <div className="modal-body">
                <div className="become_custome__form">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="input-field">
                                <input type="text" placeholder="Name*" name="name" value={values.name || ""} onChange={handleChange} />
                                <ErrorMessage message={errors.name} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="input-field">
                                <input type="text" placeholder="Company Name*" name="companyName" value={values.companyName || ""} onChange={handleChange} />
                                <ErrorMessage message={errors.companyName} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="input-field">
                                <input type="email" placeholder="Email Address*" name="email" value={values.email || ""} onChange={handleChange} />
                                <ErrorMessage message={errors.email} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="input-field">
                                <input type="text" placeholder="Phone Number" name="phone" value={values.phone || ""} onChange={handleChange} />
                                <ErrorMessage message={errors.phone} />
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="browse-input">
                                <div className="browse-field">
                                    <div className="browse-button company-logo">Company Logo</div>
                                    <input type="file" name="logo" onChange={handleFile} accept="image/*" />
                                </div>
                                { values?.logo?.size > 0 ? <div className="browsed-img"><img src={URL.createObjectURL(values?.logo)} alt="" /></div>:(<></>)}
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="input-field"> <textarea placeholder="General Information" name="info" value={values.info} onChange={handleChange} /></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" onClick={handleSubmit} className="primary-btn-outline" data-dismiss="modal">Submit</button>
                <button type="button" onClick={handleReset} className="primary-btn-outline">Reset</button>
            </div>
        </>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
    connectPostData: postData,
    connectSetShowSuccessMessage: setShowSuccessMessage
};

export default connect(mapStateToProps, mapActionsToProps)(BecomeOurCustomerForm)