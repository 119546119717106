import { SET_JOB_DATA, SET_TAG_DATA, SET_WEBSITE_DATA, SET_WEBSITE_METADATA } from "../constants/website";

const initialState = {
    pages: [],
    metadata: {},
    tags: null,
    jobs: null
}

export default function (state = initialState, action: any) {
    switch (action.type) {
        case SET_WEBSITE_DATA:
            return {
                ...state,
                pages: action.payload
            };
        case SET_WEBSITE_METADATA:
            return {
                ...state,
                metadata: action.payload
            };
        case SET_TAG_DATA:
            return {
                ...state,
                tags: action.payload
            };
        case SET_JOB_DATA:
            return {
                ...state,
                jobs: action.payload
            };
        default:
            return state;
    }
}