import React from "react";
import { connect } from "react-redux";
import CustomSlider from "react-slick";
import { getFilteredListByIsShow, host, parseHTML } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";

interface ISlider extends IPageFold { }

const Slider = ({ foldData, customObjectName }: ISlider) => {

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    let sliderComponentList = getFilteredListByIsShow(foldData).map((elm: any, index: number) => (
        <div key={index} className="carousel-item">
            <img src={host + elm[customObjectName]?.Image1?.url} alt={elm[customObjectName]?.Heading} />
            <div className="hero-slider-text">
                <div className="h_s_t_in">
                    {parseHTML(elm[customObjectName]?.Heading)}
                    <h1>{parseHTML(elm[customObjectName]?.SubHeading)}</h1>
                </div>
            </div>
        </div>
    ));

    return (
        <section className="hero-section">
            <CustomSlider {...settings}>
                {sliderComponentList}
            </CustomSlider>
        </section>
    )
}

const mapStateToProps = (state: any) => ({
    
});

const mapActionsToProps = {

};

export default connect(mapStateToProps, mapActionsToProps)(Slider)