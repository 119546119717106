import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from 'react-redux'
import { Link, useHistory } from "react-router-dom";
import { setShowSuccessMessage } from "../actions/uiAction";
import { getFilteredListByIsShow, host } from "../utils/commonComponentUtils";
import SuccessMessage from "./Helper/SuccessMessage";

interface IHeader {
    foldData: any,
    showSuccessMessage: boolean,
    connectSetShowSuccessMessage: Function
}

const Header = ({ foldData,showSuccessMessage, connectSetShowSuccessMessage }: IHeader) => {

    const [scrollManageClass, setScrollManageClass] = useState("");
    const [isMenuClick, setIsMenuClick] = useState(false);
    const [isSearchClick, setIsSearchClick] = useState(false);

    const history = useHistory();

    useEffect(()=>{
        // if(showSuccessMessage){
        //     connectSetShowSuccessMessage(false);
        // }
    },[showSuccessMessage,connectSetShowSuccessMessage])

    console.log('success Message behavior',showSuccessMessage);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, [])

    const handleScroll = (e: any) => {
        if (window.scrollY >= 200) {
            setScrollManageClass("scrollclass");
        } else {
            setScrollManageClass("");
        }
    }

    const handleMenuClick = () => {
        setIsMenuClick(!isMenuClick);
    }

    const handleSearchClick = () => {
        setIsSearchClick(!isSearchClick);
    }

    const handleSearchText = (e:any)=>{       
        console.log(e.key, e.keyCode);
        if(e.keyCode===13){            
            history.push("/search?searchtext="+e.target.value)
        }
        
    }

    let leftMenuLinksList = getFilteredListByIsShow(foldData?.LeftMenuLinks).map((leftMenuObject: any, leftMenuObjectIndex: number) => {
        return (<li key={leftMenuObjectIndex}><Link onClick={handleMenuClick} to={leftMenuObject?.ParentPageUrl?.PageUrl}>{leftMenuObject.LinkText}</Link></li>)
    })

    return (
        <div className={scrollManageClass}>
            <div className={isMenuClick ? "lmd-menu show-menu" : "lmd-menu"} id="menuOpen">
                <nav>
                    <ul>
                        {leftMenuLinksList}
                    </ul>
                </nav>
            </div>

            <header className="lmd-header">
                <div className="container-fluid">
                    <div className="custom-row">
                        <div className="site-logo">
                            <Link to="/">
                                <img src={host + foldData?.Logo?.url} alt="header logo" />
                            </Link>
                        </div>
                        <div className="lmd-hdr-right">
                            <div className={isSearchClick ? "main-search open-search" : "main-search"} id="searchOpen">
                                <div className="search-form">
                                    <input type="text" placeholder="Search here" onKeyDown={handleSearchText} />
                                </div>
                                <div className="search-icons">
                                    <button id="searchclick" onClick={handleSearchClick} />
                                </div>
                            </div>
                            <div className="menu-icon">
                                <button onClick={handleMenuClick} className={isMenuClick ? "close-menu" : ""}>
                                    <span className="hamb-top" />
                                    <span className="hamb-mid" />
                                    <span className="hamb-btm" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {showSuccessMessage?(<SuccessMessage setReduxSuccesMessage={connectSetShowSuccessMessage}/>):(<></>)}
        </div>
    )
}

const mapStateToProps = (state: any) => ({    
    showSuccessMessage : state.UI.successMessage
});

const mapActionsToProps = {
    connectSetShowSuccessMessage: setShowSuccessMessage
}

export default connect(mapStateToProps,mapActionsToProps)(Header)
