
import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { setShowSuccessMessage } from "../../../actions/uiAction";
import { postData } from "../../../actions/websiteAction";
import { JOIN_US } from "../../../constants/localStorage";
import { validate, validateOnSubmit } from "../../../utils/FormValidation/JoinUsFormValidation";
import ErrorMessage from "../../Helper/ErrorMessage";

interface IJoinUsForm {
    connectPostData: Function
    closeForm: Function,
    jobs: any,
    connectSetShowSuccessMessage: Function
}

const JoinUsForm = ({ connectPostData, closeForm, jobs, connectSetShowSuccessMessage }: IJoinUsForm) => {

    const initialState = {
        name: null,
        email: null,
        job: null,
        reference: null,
        image: {} as any,
        resume: {} as any,
        details: null
    }

    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState({} as any);

    const handleChange = (e: any) => {
        const myValue = {
            ...values,
            [e.target.name]: e.target.value
        };
        setValues(myValue);
        setErrors(validate(myValue));
    }

    const handleFile = (e: any) => {
        setValues({
            ...values,
            [e.target.name]: e.target.files[0]
        })
    }

    const handleSubmit = (e: any) => {
        let myErrors = validateOnSubmit(values);
        setErrors(myErrors);
        if (_.isEmpty(myErrors) && values !== initialState) {
            const data = {
                "Heading": values.name,
                "Email": values.email,
                "SubHeading": values.job,
                "NameOfReference": values.reference,
                "Description": values.details,
                "published_at": null
            }

            const formData = new FormData();
            formData.append('data', JSON.stringify(data));

            if (values.image && values.image.name)
                formData.append(`files.Image1`, values.image, values.image?.name);

            if (values.resume && values.resume.name)
                formData.append(`files.Resume`, values.resume, values.resume?.name);

            connectPostData({ url: "/employee-cots", data: formData }, (res: any) => {
                setValues(initialState);                
                connectSetShowSuccessMessage(true);
                localStorage.setItem(JOIN_US, JSON.stringify(res.data));
                closeForm();
            });
        }
    }

    const handleReset = (e: any) => {
        setValues(initialState);
        setErrors({});
    }

    const jobListing = jobs && jobs.map((jobElement: any, jobIndex: number) => <option key={jobIndex}>{jobElement.Heading}</option>);

    return (

        <div className="row">
            <div className="col-sm-4">
                <div className="input-field">
                    <input type="text" placeholder=" Name" value={values?.name || ""} onChange={handleChange} name="name" />
                    <ErrorMessage message={errors.name} />
                </div>
            </div>
            <div className="col-sm-4">
                <div className="input-field">
                    <input type="email" placeholder=" Email" value={values?.email || ""} onChange={handleChange} name="email" />
                    <ErrorMessage message={errors.email} />
                </div>
            </div>
            <div className="col-sm-4">
                <div className="input-field">
                    {/* Todo: Make this dynamic */}
                    <select className="selectbox" value={values?.job || ""} onChange={handleChange} name="job">
                        <option value={""}>Select Job</option>
                        {jobListing}
                    </select>
                    <ErrorMessage message={errors.job} />
                </div>
            </div>
            <div className="col-sm-4">
                <div className="input-field">
                    <input type="text" placeholder=" Name of your Reference (if any)" value={values?.reference || ""} onChange={handleChange} name="reference" />
                    <ErrorMessage message={errors.reference} />
                </div>
            </div>
            <div className="col-sm-4">
                <div className="input-field">
                    <div className="browse-input">
                        <div className="browse-field">
                            <div className="browse-button company-logo">Image</div>
                            <input type="file" onChange={handleFile} name="image" accept="image/*" />
                        </div>
                        {values?.image?.size > 0 ? <div className="browsed-img"><img src={URL.createObjectURL(values?.image)} alt="" /></div> : (<></>)}
                    </div>
                </div>
            </div>
            <div className="col-sm-4">
                <div className="input-field">
                    <div className="browse-input">
                        <div className="browse-field">
                            <div className="browse-button company-logo">Resume</div>
                            <input type="file" onChange={handleFile} name="resume" accept=".doc, .docx, .pdf" />
                        </div>
                        {values?.resume?.size > 0 ? <div className="file-name">{values?.resume?.name}</div> : (<></>)}
                    </div>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="input-field">
                    <textarea placeholder=" Details" value={values?.details || ""} onChange={handleChange} name="details" />
                    <ErrorMessage message={errors.details} />
                </div>
            </div>
            <div className="col-sm-12">
                <div className="input-submit">
                    <button className="primary-btn-outline" onClick={handleSubmit}>Submit</button>
                    <button className="primary-btn-outline" onClick={handleReset}>Reset</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    jobs: state.website.jobs
});

const mapActionsToProps = {
    connectPostData: postData, 
    connectSetShowSuccessMessage: setShowSuccessMessage
};

export default connect(mapStateToProps, mapActionsToProps)(JoinUsForm)