import React, { useReducer, useState } from "react";
import { connect } from "react-redux";
import { JOIN_US } from "../../../constants/localStorage";
import { host } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";
import ClaimYourSpacePopUp from "./ClaimYourSpacePopUp";

interface ITeamHeader extends IPageFold { }

const TeamHeader = ({ foldData,customObjectName }: ITeamHeader) => {

    const [showClaimPopUp, setShowClaimPopUp] = useState(false);
    const claimPopUpProps = {showClaimPopUp,setShowClaimPopUp};
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const handleClaimPopUp = ()=>{
        setShowClaimPopUp(!showClaimPopUp);
    }

    const isImageInLocalStorage = () => {
        try {
            const joinUsObject = JSON.parse(localStorage.getItem(JOIN_US) as string);
            if (!joinUsObject)
                return false;

            if (joinUsObject?.Image1?.url?.length > 0)
                return true;

            return false;
        } catch (e) {
            return false;
        }
    }

    const getImageInLocalStorage = () => {
        const becomeOurCustomerObject = JSON.parse(localStorage.getItem(JOIN_US) as string);
        return becomeOurCustomerObject?.Image1?.url;
    }

    const handleClear = ()=>{
        localStorage.setItem(JOIN_US, "");
        forceUpdate();
    }

    return (
        <>
            <section className="team-top-section" style={{ backgroundImage: 'url(static/images/team-banner.jpg)' }}>
                <div className="container">
                    <h1>Team</h1>
                    <div className="claim-your-space">
                        <i>
                            <img src={isImageInLocalStorage() ? host + getImageInLocalStorage():"static/images/default-pic.png"} alt="" />
                            {!isImageInLocalStorage()?(<strong>Claim your space</strong>):(<></>)}
                           
                        </i>
                        {isImageInLocalStorage()?(<button onClick={handleClear}>Clear</button>):(<></>)}
                        {!isImageInLocalStorage()?(<button onClick={handleClaimPopUp} data-toggle="modal" data-target="#joinUs">Join Us</button>):(<></>)}
                    </div>
                </div>
            </section>
            {showClaimPopUp ? <ClaimYourSpacePopUp claimPopUpProps={claimPopUpProps} /> : (<></>)}
        </>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(TeamHeader)