import React, { forwardRef } from "react";
import { Scrollbars } from "react-custom-scrollbars";


const renderThumb = ({ style, ...props }: any) => {
    const thumbStyle = {
        width: 4,
        borderRadius: 6,
        backgroundColor: "rgba(35, 49, 86, 0.8)"
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbar = (props: any, ref: any) => (
    <Scrollbars
        ref={ref}
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        // autoHide
        // autoHideTimeout={300}
        // autoHideDuration={100}
        autoHeight
        autoHeightMin={100}
        autoHeightMax={500}
        {...props}
    />
);

export default forwardRef(CustomScrollbar);