import * as React from "react";
import { connect } from "react-redux";
import { Route, Router } from "react-router-dom";
import history from "./utils/history";
import Pages from "./routes/Pages";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { getJobData, getTagData, getWebsiteData, getWebsiteMetadata } from "./actions/websiteAction";
import { useEffect } from "react";

interface IProps {
    metadata: any,
    pages: any,
    tags: any,
    jobs: any,
    connectGetWebsiteData: Function,
    connectGetWebsiteMetadata: Function,
    connectGetTagData: Function,
    connectGetJobData: Function
}

const App = ({ metadata, pages, connectGetWebsiteData, connectGetWebsiteMetadata, connectGetTagData, tags, connectGetJobData, jobs }: IProps) => {

    useEffect(() => {
        if (!(pages.length > 0)) {
            connectGetWebsiteData();
        }
    }, [connectGetWebsiteData, pages]);

    useEffect(() => {
        if (!(Object.keys(metadata).length > 0)) {
            connectGetWebsiteMetadata();
        }
    }, [connectGetWebsiteMetadata, metadata]);

    useEffect(() => {
        if (tags === null)
            connectGetTagData();
    }, [connectGetTagData, tags]);

    useEffect(() => {
        if (jobs === null)
            connectGetJobData();
    }, [connectGetJobData, jobs]);

    const app = (
        <Router history={history}>
            <Header foldData={metadata?.Header} />
            <Route component={Pages} />
            <Footer foldData={metadata?.Footer} />
        </Router>
    );

    return (
        <div className="App" id="mainContainer">
            {app}
        </div>
    );
}

const mapStateToProps = (state: any) => ({
    pages: state.website.pages,
    metadata: state.website.metadata,
    tags: state.website.tags,
    jobs: state.website.jobs,
});

const mapDispatchToProps = {
    connectGetWebsiteData: getWebsiteData,
    connectGetWebsiteMetadata: getWebsiteMetadata,
    connectGetTagData: getTagData,
    connectGetJobData: getJobData
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(App);
