import React, { useState } from "react";
import { connect } from "react-redux";
import IPageFold from "../../../utils/IPageFold";
import BlogHeader from "./BlogHeader";
import BlogList from "./BlogList";
import BlogSearch from "./BlogSearch";

interface IBlog extends IPageFold { }

const Blog = ({ foldData, customObjectName }: IBlog) => {

  const [selectedTagId, setSelectedTagId] = useState("");
  const [searchTerm,setSelectedSearchTerm] = useState("");

  const selectedTagProps = { selectedTagId, setSelectedTagId };
  const searchTermProps = {searchTerm,setSelectedSearchTerm};

  return (
    <div className="blog-page">
      <BlogHeader foldData={foldData?.BlogHeader} customObjectName={customObjectName} />
      <BlogSearch foldData={foldData?.BlogSearch} customObjectName={customObjectName} selectedTagProps={selectedTagProps} searchTermProps={searchTermProps} />
      <BlogList foldData={foldData?.BlogBox} customObjectName={customObjectName} selectedTagProps={selectedTagProps} searchTermProps={searchTermProps} />
    </div>

  )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(Blog)