
import React, { useEffect, useState } from "react";

interface ISuccessMessage{
    setReduxSuccesMessage: Function
}

const SuccessMessage = ({setReduxSuccesMessage}:ISuccessMessage) => {

    const STAY_TIME = 3000; // in ms
    const [showMessage, setShowMessage] = useState(true);

    useEffect(() => {
        const interval = setInterval(() => {            
            setShowMessage(false);
            setReduxSuccesMessage(false);
        }, STAY_TIME);
        return () => {
            clearInterval(interval);
        }
    }, [setReduxSuccesMessage]);

    return (
        showMessage ? (<div className="form-submitted">Your request was submitted successfully and we will get in touch with you soon.</div>) : (<></>)
    )
}

export default (SuccessMessage)