import { combineReducers } from 'redux'
import uiReducer from './uiReducer'
import websiteReducer from './websiteReducer'


const reducer = combineReducers({   
    UI: uiReducer,
    website:websiteReducer
});

export default reducer;