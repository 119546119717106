import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getDaysFromNow, getFilteredListByIsShow, getUrl, host, parseHTML } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";
import PaginationBox from "./PaginationBox";

interface IBlogList extends IPageFold {
    tags: Map<string, string>,
    selectedTagProps: {
        selectedTagId: string;
        setSelectedTagId: React.Dispatch<React.SetStateAction<string>>;
    },
    searchTermProps: {
        searchTerm: string;
        setSelectedSearchTerm: React.Dispatch<React.SetStateAction<string>>;
    }
}

const BlogList = ({ foldData, customObjectName, tags, selectedTagProps, searchTermProps }: IBlogList) => {

    const location = useLocation();
    const SUBSTRING_LENGTH = 110;
    const BLOG_ITEM_TO_SHOW_ON_PAGE = 10;

    const [pageNumber, setPageNumber] = useState(0);

    const { selectedTagId } = selectedTagProps;
    const { searchTerm } = searchTermProps;

    useEffect(() => {
        setPageNumber(0);
    }, [searchTerm, selectedTagId])

    const getStartIndex = () => {
        return pageNumber * 10;
    }

    const getEndIndex = () => {
        return getStartIndex() + 9;
    }

    const getFilteredListByPagination = (list: any) => {
        try {
            return (list && list.length > 0) ? list.filter((blogElement: any, blogIndex: number) => blogIndex >= getStartIndex() && blogIndex <= getEndIndex()) : [];
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    const getFilteredListByTagId = (list: any) => {
        try {
            if (selectedTagId !== "")
                return (list && list.length > 0) ? list.filter((blogElement: any) => blogElement[customObjectName]?.Tags?.includes(selectedTagId)) : [];
            else
                return list;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    const getFilteredListBySearchTerm = (list: any) => {
        try {
            const searchTermLower = searchTerm.toLowerCase();
            if (searchTerm.length >= 1)
                return (list && list.length > 0) ? list.filter((blogElement: any) => blogElement[customObjectName]?.Heading?.toLowerCase().includes(searchTermLower) || blogElement[customObjectName]?.Description?.substring(0, SUBSTRING_LENGTH).toLowerCase().includes(searchTermLower)) : [];
            else
                return list;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    const getTotalBlogItems = ()=>{
        return getFilteredListBySearchTerm(getFilteredListByTagId(getFilteredListByIsShow(foldData))).length;
    }

    let blogList = getFilteredListByPagination(getFilteredListBySearchTerm(getFilteredListByTagId(getFilteredListByIsShow(foldData)))).map((blogItem: any, blogIndex: number) => (
        <div className="blog-list-box" key={blogIndex}>
            <div className="blog-box--inner">
                <div className="blog-list--img">
                    <img src={host + blogItem[customObjectName]?.Image1?.url} alt="" />
                </div>
                <div className="blog-list--content">
                   
                    <h4><Link to={getUrl(blogItem[customObjectName], location?.pathname)}>{blogItem[customObjectName]?.Heading}</Link>
                    </h4>
                    {blogItem[customObjectName]?.Tags?.map((tagId: string, tagIndex: number) => <div key={tagIndex} className="blog-tag-name">{tags.get(tagId)}</div>)}
                    <div className="blog-des">
                        {parseHTML(blogItem[customObjectName]?.Description?.substring(0, SUBSTRING_LENGTH))}
                    </div>
                    <div className="blog-time-author">
                        <div className="blog-time">{getDaysFromNow(blogItem[customObjectName]?.published_at)}</div>
                        <div className="blog-author">by {blogItem[customObjectName]?.Author}</div>
                    </div>
                </div>
            </div>
        </div>

    ));

    return (

        <section className="blog-list-section">
            <div className="container">
                <h5>Displaying Posts ({blogList.length})</h5>
                <div className="blog-list--inner">
                    {blogList}
                </div>
                {getTotalBlogItems() > BLOG_ITEM_TO_SHOW_ON_PAGE ? (<PaginationBox numberOfItems={getTotalBlogItems()} itemsPerPage={BLOG_ITEM_TO_SHOW_ON_PAGE} pageNumberProps={{ pageNumber, setPageNumber }} />) : (<></>)}
            </div>
        </section>
    )
}

const mapStateToProps = (state: any) => ({
    tags: state.website.tags
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(BlogList)