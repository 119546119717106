import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getSubUrlFromUrl, getUrl, host, parseHTML } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";

interface IArticleDetail extends IPageFold { }

const ArticleDetail = ({ foldData, customObjectName }: IArticleDetail) => {
    
    const history = useHistory();
    const location = useLocation();

    const [article, setArticle] = useState(null as any);
    const [url, setUrl] = useState("");

    useEffect(() => {
        const getRelevantCustomer = (foldData: any) => {
            let requiredCustomer = foldData.filter((fold: any) => {
                return getUrl(fold[customObjectName], getSubUrlFromUrl(location.pathname)) === location.pathname
            })[0];
            
            return requiredCustomer;
        }

        const redirectToErrorPage = () => {
            // a hack in case back button is clicked 
            if (location.pathname !== getSubUrlFromUrl(location.pathname)) {
                // history.push("/404");
            }
        }

        if (!article || location.pathname !== url) {
            let selectedCustomerFromUrl = getRelevantCustomer(foldData);
            if (selectedCustomerFromUrl) {
                setArticle(selectedCustomerFromUrl);
                setUrl(location.pathname);
            } else {
                setUrl(location.pathname);
                redirectToErrorPage();
            }
        }
    }, [foldData, article, location.pathname, url, history,customObjectName])
    if (article && article[customObjectName])
        return (
            <>
                <div>
                    <section className="page-banner-section" style={{ backgroundImage: 'url(' + host + article[customObjectName]?.Image1?.url + ')' }}>
                        <div className="container">
                            <h1>{article[customObjectName]?.Heading}</h1>
                        </div>
                    </section>
                    <section className="article-details-page">
                        <div className="container">
                            <div className="article-details-content">                                
                                {parseHTML(article[customObjectName]?.Description)}
                            </div>
                        </div>
                    </section>
                </div>

            </>
        )
    else
        return (<></>)
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(ArticleDetail)