import React, { useState } from "react";
import { connect } from "react-redux";
import IPageFold from "../../../utils/IPageFold";
import ContactForm from "../Forms/ContactForm";
import JoinUsForm from "../Forms/JoinUsForm";

interface IContactFormSection extends IPageFold { }

const ContactFormSection = ({ foldData, customObjectName }: IContactFormSection) => {

    const [formSwitch, setFormSwitch] = useState(true);

    const handleFormSwitch = (e: any, switcher: boolean) => {
        e.preventDefault();
        setFormSwitch(switcher);
    }

    return (
        <section className="join-contact-form-section">
            <div className="container">
                <div className="form-tabs--top">
                    <ul className="nav" id="myTab" role="tablist">
                        <li>
                            <a onClick={(e) => { handleFormSwitch(e, true) }} className={formSwitch ? "active show" : ""} id="tab-a" href="#taba" role="tab" aria-controls="taba" aria-selected="true">Contact Us</a>
                        </li>
                        <li>
                            <a onClick={(e) => { handleFormSwitch(e, false) }} className={!formSwitch ? "active show" : ""} id="tab-b" href="#tabb" role="tab" aria-controls="tabb" aria-selected="false">Join Our Team</a>
                        </li>
                    </ul>
                </div>
                <div className="form-tabs--btm tab-content" id="myTabContent">
                    <div className={formSwitch ? "tab-pane fade active show" : "tab-pane fade"} id="taba" role="tabpanel" aria-labelledby="tab-a">
                        <div className="contact-form">
                            <ContactForm />
                        </div>
                    </div>
                    <div className={!formSwitch ? "tab-pane fade active show" : "tab-pane fade"} id="tabb" role="tabpanel" aria-labelledby="tab-b">
                        <div className="joinus-form">
                            <JoinUsForm closeForm={()=>{}} />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(ContactFormSection)