import axios from "axios";
import { SET_JOB_DATA, SET_TAG_DATA, SET_WEBSITE_DATA, SET_WEBSITE_METADATA } from "../constants/website";
import { LOADING_UI } from "../constants/ui";

export const getWebsiteData = (cb: Function) => async (dispatch: any) => {
    dispatch({ type: LOADING_UI })
    try {
        let getString = '/lmd-websites'; // default   
        // let defaultFilter = { params: { "_sort": "order:ASC" } }
        let websiteDataResponse = await axios.get(getString, {});
        dispatch({ type: SET_WEBSITE_DATA, payload: websiteDataResponse.data });
    } catch (err) {
        console.error(err);
    }
}

export const getWebsiteMetadata = (cb: Function) => async (dispatch: any) => {
    dispatch({ type: LOADING_UI })
    try {
        let getString = '/lmd-website-metadata';
        let metadataResponse = await axios.get(getString, {});
        dispatch({ type: SET_WEBSITE_METADATA, payload: metadataResponse.data });
    } catch (err) {
        console.error(err);
    }
}

export const postData = (data: any, cb: Function) => async (dispatch: any) => {
    // dispatch({ type: LOADING_UI })
    try {
        let getString = data.url;
        const response = await axios.post(getString, data.data);
        cb(response);
    } catch (err) {
        console.error(err);
    }
}

const arrayToMap = (myArr: Array<any>) => {
    let myMap = new Map();
    myArr.forEach((element:any)=>{
        const id = element.id;
        const tagName = element.TagName;
        myMap.set(id,tagName);
    })

    return myMap;
}

export const getTagData = (cb: Function) => async (dispatch: any) => {
    dispatch({ type: LOADING_UI })
    try {
        let getString = '/tags-cots';
        let tagDataResponse = await axios.get(getString, {});
        const tagMap = arrayToMap(tagDataResponse.data);        
        dispatch({ type: SET_TAG_DATA, payload: tagMap });
    } catch (err) {
        console.error(err);
    }
}

export const getJobData = (cb: Function) => async (dispatch: any) => {
    dispatch({ type: LOADING_UI })
    try {
        let getString = '/job-cots';
        let jobDataResponse = await axios.get(getString, {});
        const jobMapping =jobDataResponse.data;        
        dispatch({ type: SET_JOB_DATA, payload: jobMapping });
    } catch (err) {
        console.error(err);
    }
}