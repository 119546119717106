import React from "react";
import { connect } from "react-redux";
import IPageFold from "../../../utils/IPageFold";


interface IBlogSearch extends IPageFold {
  selectedTagProps: {
    selectedTagId: string;
    setSelectedTagId: React.Dispatch<React.SetStateAction<string>>;
  },
  searchTermProps: {
    searchTerm: string;
    setSelectedSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  }
}

const BlogSearch = ({ foldData, customObjectName, selectedTagProps, searchTermProps }: IBlogSearch) => {

  const handleTagChange = (e: any) => {
    selectedTagProps.setSelectedTagId(e.target.value);
  }

  const handleSearchTextChange = (e: any) => {
    console.log("handle search text", e.target.value);
    searchTermProps.setSelectedSearchTerm(e.target.value);
  }

  return (
    <section className="blog-search-section">
      <div className="container">
        <div className="custom-row">
          <div className="blog-search">
            <input type="text" placeholder="Enter your keywords…" onChange={handleSearchTextChange} value={searchTermProps.searchTerm} />
            <div className="submit-icon" />
          </div>
          <div className="blog-select">
            <select className="selectbox" onChange={handleTagChange}>
              <option value="">Select Tags</option>
              {foldData?.Tags?.map((tag: any, tagIndex: number) => <option value={tag.id} key={tagIndex}>{tag.TagName}</option>)}
            </select>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(BlogSearch)