import { SET_ERRORS, CLEAR_ERRORS, LOADING_UI } from "../constants";
import { SET_SUCCESS_MESSAGE } from "../constants/ui";

const initialState = {
    loading: false,
    errors: null,
    successMessage: false
}
export default function (state = initialState, action: any) {
    switch (action.type) {
        case SET_ERRORS:
            return {
                ...state,
                loading: false,
                errors: action.payload
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                errors: null
            };
        case LOADING_UI:
            return {
                ...state,
                loading: true
            }
        case SET_SUCCESS_MESSAGE:
            return{
                ...state,
                successMessage: action.payload
            }    
        default:
            return state;
    }
}