import * as React from "react";


const NotFound = () => (
  <>

<section className="container-mid">

<div className="container">
    <div className="page-notfound">
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <div className="home-btn">
            <a className="button-color" href="/">Take me home</a>
        </div>
    </div>
</div>


</section>
    
  </>
);

export default NotFound;
