export const isEmpty = (inputString: string) => {
    try {
        if (inputString !== null && inputString.length === 0)
            return true;
        else
            return false;
    } catch (e) {
        return false;
    }
}

export const isNullOrEmpty = (inputString: string) => {
    try {
        if (inputString === null || inputString.length === 0)
            return true;
        else
            return false;
    } catch (e) {
        return false;
    }
}

export const isEmail = (inputString: string) =>{
    try{
        return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputString)
    }catch(e){
        return false;
    }
}

export const isLength = (start:number,end:number,inputString:string) =>{
    try{
        if(inputString && inputString.length>start && inputString.length <end )
            return true;
        else
            return false;    
    }catch(e){
        return false;
    }
}

export const isCharacterSpaceOnly = (inputString:string) =>{
    try{
        return /^[a-z][a-z\s]*$/.test(inputString.toLowerCase());
    }catch(e){
        return false;
    }
}