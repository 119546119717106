import * as React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import HomePage from "../pages/HomePage";
import NotFound from "../pages/NotFound";

const Pages = () => {
  return (
    <Switch>
      {/* For 404 Page */}
      <Route path="/404" component={NotFound} />
      <Route path="/" component={HomePage} />      
      
      
    </Switch>
  );
};


const mapStateToProps = (state: any) => ({
  metadata: state.website.metadata
});

export default connect(mapStateToProps)(Pages);
// export default withRouter(connect(
//   mapStateToProps,
// )(Pages))


