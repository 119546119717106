import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getFoldOrderReduced, getUrl, host, parseHTML } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";

interface IPageSearchComponent extends IPageFold {
    pages: any
}

const LOAD_MORE_DEFAULT = 6;

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}


const PageSearchComponent = ({ foldData, customObjectName, pages }: IPageSearchComponent) => {

    const [allTextData, setAllTextData] = useState([] as any);
    const [loadMoreLimit, setLoadMoreLimit] = useState(LOAD_MORE_DEFAULT);
    const [searchTerm,setSearchTerm] = useState("");

    let query = useQuery();
    
    useEffect(()=>{
        const searchItem = query.get("searchtext") || "";
        setSearchTerm(searchItem);
    },[query])

    useEffect(() => {

        const putAllDataIntoArray = (pageData: any) => {
            try {
                if (pageData) {
                    let allData = [] as any;
                    for (let i = 0; i < pageData.length; i++) {
                        const foldOrder = getFoldOrderReduced(pageData[i])[0];
                        let dataArray = pageData[i]?.PageComponent[0][foldOrder.foldName];
                        let subUrl = pageData[i]?.ParentPage?.PageUrl
                        dataArray.forEach((dataElement: any) => allData.push({ ...dataElement[foldOrder.customObjectName], "FullSubUrl": getUrl(dataElement[foldOrder.customObjectName], subUrl) }))
                    }
                    setAllTextData(allData);                    
                }
            } catch (e) {
                console.error(e);
            }
        }

        const pageIds = foldData[customObjectName] && foldData[customObjectName].length > 0 && foldData[customObjectName].map((pageObj: any) => { return pageObj.id });
        const pageData = pages && pages.length > 0 && pages.filter((pageObject: any) => pageIds.includes(pageObject.id));
        putAllDataIntoArray(pageData);

    }, [foldData, customObjectName, pages])

    const getFilteredListBySearchTerm = (list: any) => {
        try {
            const searchTermLower = searchTerm.toLowerCase();
            if (searchTerm.length >= 1)
                return (list && list.length > 0) ? list.filter((element: any) => element?.Heading?.toLowerCase().includes(searchTermLower) || element?.Description?.toLowerCase().includes(searchTermLower)) : [];
            else
                return [];
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    const handleLoadMore = () => {
        setLoadMoreLimit(loadMoreLimit + LOAD_MORE_DEFAULT);
    }

    const getSearchResultLength = () => {
        return getFilteredListBySearchTerm(allTextData).length;
    }

    let searchList = getFilteredListBySearchTerm(allTextData).filter((item: any, index: number) => index < loadMoreLimit).map((searchItem: any, searchIndex: number) => (
        <div key={searchIndex} className="result-row">
            <img alt={searchItem?.Heading} src={host + searchItem?.Image1?.url} />
            <h3>{searchItem?.Heading}</h3>
            <div className="result-des">
                {parseHTML(searchItem?.Description?.substring(0, 600))}
            </div>
            <div className="page_link">
                <Link to={searchItem.FullSubUrl}>Read More {">>"}</Link>
            </div>
            <div className="clear" />
        </div>
    ))

    return (
        <>
            <section className="search-result-section">
                <div className="container">
                    <h2>Search Results</h2>
                    <div className="search-result-inner">
                        {searchList}
                        {loadMoreLimit < getSearchResultLength() ? (<div className="result-load-more">
                            <button onClick={handleLoadMore} className="primary-btn-outline">Load More </button>
                        </div>) : <></>}

                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    pages: state.website.pages
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(PageSearchComponent)