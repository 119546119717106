import React from "react";
import { connect } from "react-redux";
import IPageFold from "../../../utils/IPageFold";

interface IClientHeading extends IPageFold { }

const ClientHeading = ({ foldData }: IClientHeading) => {

    if (foldData && foldData.isShow)
        return (<h2>{foldData.Heading}</h2>)
    else
        return <></>
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(ClientHeading)