import _ from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { setShowSuccessMessage } from "../../../actions/uiAction";
import { postData } from "../../../actions/websiteAction";
import { validate, validateOnSubmit } from "../../../utils/FormValidation/ContactFormValidation";
import ErrorMessage from "../../Helper/ErrorMessage";

interface IContactForm {
    connectPostData: Function,
    connectSetShowSuccessMessage: Function
}

const ContactForm = ({ connectPostData, connectSetShowSuccessMessage }: IContactForm) => {

    const initialState = {
        name: null,
        email: null,
        subject: null,
        message: null
    }

    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState({} as any);

    const handleChange = (e: any) => {
        const myValue = {
            ...values,
            [e.target.name]: e.target.value
        };
        setValues(myValue);
        setErrors(validate(myValue));
    }

    const handleSubmit = (e: any) => {
        // "published_at": null so that the mode is set to draft rather than publish in strapi
        let myErrors = validateOnSubmit(values);
        setErrors(myErrors);
        if (_.isEmpty(myErrors) && values !== initialState) {
            const data = {
                "Name": values.name,
                "Email": values.email,
                "Subject": values.subject,
                "Message": values.message,
                "published_at": null
            }
            connectPostData({ url: "/contact-us-cots", data }, () => {
                setValues(initialState);
                connectSetShowSuccessMessage(true);
            });
        }
    }

    const handleReset = (e: any) => {
        setValues(initialState);
        setErrors({});
    }

    return (

        <div className="row">
            <div className="col-sm-4">
                <div className="input-field">
                    <input type="text" placeholder=" Name" name="name" onChange={handleChange} value={values.name || ""} />
                    <ErrorMessage message={errors.name} />
                </div>
            </div>
            <div className="col-sm-4">
                <div className="input-field">
                    <input type="email" placeholder=" Email" name="email" onChange={handleChange} value={values.email || ""} />
                    <ErrorMessage message={errors.email} />
                </div>
            </div>
            <div className="col-sm-4">
                <div className="input-field">
                    <input type="text" placeholder=" Subject" name="subject" onChange={handleChange} value={values.subject || ""} />
                    <ErrorMessage message={errors.subject} />
                </div>
            </div>
            <div className="col-sm-12">
                <div className="input-field">
                    <textarea placeholder=" Message" name="message" onChange={handleChange} value={values.message || ""} />
                    <ErrorMessage message={errors.message} />
                </div>
            </div>
            <div className="col-sm-12">
                <div className="input-submit">
                    <button className="primary-btn-outline" onClick={handleSubmit}>Submit</button>
                    <button className="primary-btn-outline" onClick={handleReset}>Reset</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
    connectPostData: postData,
    connectSetShowSuccessMessage: setShowSuccessMessage
};

export default connect(mapStateToProps, mapActionsToProps)(ContactForm)