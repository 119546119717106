import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducers';
const initialState = {};
const middleware = [thunk];

let composeEnhancers;
if (
    process.env.NODE_ENV !== "production" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  } else {
    composeEnhancers = compose;
  }


const store = createStore(reducer, initialState, composeEnhancers(applyMiddleware(...middleware)));
export default store;