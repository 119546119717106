import React from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getFilteredListByIsShow, getUrl, parseHTML } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";

interface ICareerBox extends IPageFold { }

const CareerBox = ({ foldData, customObjectName }: ICareerBox) => {

    const location = useLocation();

    let careerBoxListing = getFilteredListByIsShow(foldData).map((careerBox: any, careerBoxIndex: number) => (
        <div className="col-sm-4" key={careerBoxIndex}>
            <div className="career-box">
                
                <h3>{careerBox[customObjectName]?.Heading}</h3>
                <div className="career-location">
                Location - {careerBox[customObjectName]?.Location}<br />
                  Requirements - {careerBox[customObjectName]?.RequireExperience}
                </div>
                <div className="career-des">
                {parseHTML(careerBox[customObjectName]?.SubHeading)}
                </div>
                <div className="career-read-more">
                    <Link className="primary-btn-outline" to={getUrl(careerBox[customObjectName],location?.pathname)}>Read More {" >>"}</Link>
                </div>
            </div>
        </div>))

    return (
        <>
            <div className="career-list">
                <div className="row">
                {careerBoxListing}
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(CareerBox)