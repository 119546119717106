import React, { useState } from "react";
import { connect } from "react-redux";
import { getFilteredListByIsShow, host, parseHTML } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";

interface IFourBlockSection extends IPageFold { }

const FourBlockSection = ({ foldData, customObjectName }: IFourBlockSection
) => {

    const [readMore, setReadMore] = useState(false)

    const [extendedDataShowManage, setExtendedDataShowManage] = useState({
        isActive: false,
        index: -1,
        extendedData: {} as any
    })

    const handleHeadingClick = (e: any, extendedData: any, index: number) => {
        setExtendedDataShowManage({
            isActive: true,
            extendedData,
            index
        })
    }

    const handleCloseExtendedBlockClick = () => {
        setExtendedDataShowManage({
            ...extendedDataShowManage,
            isActive: false,
            index: -1
        });
    }

    const handleReadMore = (e: any) => {
        e.preventDefault();
        setReadMore(!readMore);
    }

    let fourBlockSectionList = getFilteredListByIsShow(foldData).map((elm: any, index: number) => (
        <div className="col-sm-3" key={index}>
            <div onClick={(e) => handleHeadingClick(e, elm[customObjectName], index)} className={extendedDataShowManage.isActive && extendedDataShowManage.index === index ? "four_block__in active" : "four_block__in"}>
                <i className="hvr-float-shadow"><img src={host + elm[customObjectName]?.Icon.url} alt={elm[customObjectName]?.Heading} /></i>
                <h4>{elm[customObjectName]?.Heading}</h4>
                <div className="f_txt">
                    <p>{elm[customObjectName]?.SubHeading?.substring(0, 600)}</p>
                </div>
                <div className="arrow-down-img" />
            </div>
        </div>

    ));

    return (
        <>
            <section className="four-block-section">
                <div className="container">
                    <div className="row">
                        {fourBlockSectionList}
                    </div>
                </div>
            </section>
            <section className="four-block-content" style={{ display: extendedDataShowManage.isActive ? 'block' : 'none' }}>
                <div className="container clearfix b-f-left">
                    <button onClick={handleCloseExtendedBlockClick} className="fold_close"><img src="static/images/flod-close-icon.png" alt="" /></button>
                    <div className="big-picture">
                        <img src={host + extendedDataShowManage.extendedData?.Image1?.url} alt="" />
                    </div>
                    <div className={readMore ? "picture-content showcontent" : "picture-content"}>
                        <h4>{extendedDataShowManage?.extendedData?.Heading}</h4>
                       <div className="four-fold-txt-box">
                       <h5>{parseHTML(extendedDataShowManage?.extendedData?.SubHeading)}</h5>
                        <div className="b_p_txt">
                            {readMore ? parseHTML(extendedDataShowManage?.extendedData?.Description) : parseHTML(extendedDataShowManage?.extendedData?.Description?.substring(0, 400))}
                        </div>
                       </div>
                       

                        <div className="page_link">
                            {extendedDataShowManage?.extendedData?.Description?.length > 600 ? (<a href="#placeholder" onClick={handleReadMore}>{readMore ? "Show Less <<" : "Read More >>"}</a>) : (<></>)}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    fourBlockSection: state.homePage?.homePageData?.FourBlockSectionComponent
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(FourBlockSection
)