import React, { useReducer, useState } from "react";
import { connect } from "react-redux";
import { getFilteredListByIsShow, getUrl, host, parseHTML } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";
import { Link, useLocation } from "react-router-dom";
import BecomeOurCustomerForm from "../Forms/BecomeOurCustomerForm";
import { BECOME_OUR_CUSTOMER } from "../../../constants/localStorage";

interface IClientBox extends IPageFold { }

const ClientBox = ({ foldData, customObjectName }: IClientBox) => {

    const location = useLocation();

    const SHOW_NO_FORM = 0;
    const SHOW_BECOME_CUSTOMER_FORM = 2;

    const [showRelevantForm, setShowRelevantForm] = useState(SHOW_NO_FORM);
    const [flippedIcon, setflippedIcon] = useState(-1);

    const [isBecomeOurCustomerFlipped, setIsBecomeOurCustomerFlipped] = useState(false);

    const [, forceUpdate] = useReducer(x => x + 1, 0);


    const handleFlipClick = (e: any, clientBoxIndex: number) => {
        setflippedIcon(clientBoxIndex);        
        setIsBecomeOurCustomerFlipped(false);
    }

    const handleFlipAndFormSwitch = (e: any, formNumber: number) => {

        if (isImageInLocalStorage()) {
            setIsBecomeOurCustomerFlipped(!isBecomeOurCustomerFlipped);
            return;
        }

        setShowRelevantForm(formNumber);

        if (formNumber === SHOW_NO_FORM) {
            document.body.classList.remove('modal-open');
        } else {
            document.body.classList.add('modal-open');
        }
    }

    const closeForm = () => {
        setShowRelevantForm(SHOW_NO_FORM);
        document.body.classList.remove('modal-open');        
    }

    const isImageInLocalStorage = () => {
        try {
            const becomeOurCustomerObject = JSON.parse(localStorage.getItem(BECOME_OUR_CUSTOMER) as string);
            if (!becomeOurCustomerObject)
                return false;

            if (becomeOurCustomerObject?.CompanyLogo?.url?.length > 0)
                return true;

            return false;
        } catch (e) {
            return false;
        }
    }

    const getImageInLocalStorage = () => {
        const becomeOurCustomerObject = JSON.parse(localStorage.getItem(BECOME_OUR_CUSTOMER) as string);
        return becomeOurCustomerObject?.CompanyLogo?.url;
    }

    const getbBecomeOurCustomerObject = ()=>{
        const becomeOurCustomerObject = JSON.parse(localStorage.getItem(BECOME_OUR_CUSTOMER) as string);
        return becomeOurCustomerObject;
    }

    const handleBecomeOurCustomerClear = (e: any) => {          
        localStorage.setItem(BECOME_OUR_CUSTOMER, "");
        setIsBecomeOurCustomerFlipped(false);
        forceUpdate();
    }

    let clientBoxList = getFilteredListByIsShow(foldData).map((clientBox: any, clientBoxIndex: number) => (
        <div key={clientBoxIndex} className="customers-box">
            <div className={clientBoxIndex === flippedIcon ? "flip-card active" : "flip-card"}>
                <div className="flip-card-inner">
                    <div className="flip-card-front" onClick={(e) => { handleFlipClick(e, clientBoxIndex) }}>
                        <img src={host + clientBox[customObjectName]?.Icon?.url} alt={clientBox[customObjectName]?.Heading} />
                    </div>
                    <div className="flip-card-back">
                        <div className="flip-card-back-inner">
                            <h3>{clientBox[customObjectName]?.Heading}</h3>
                            <div className="flip_txt">
                                {parseHTML(clientBox[customObjectName]?.SubHeading?.substring(0, 100))}
                            </div>
                            {(clientBox.isCaseStudy) ? <div className="url_btn"><Link to={getUrl(clientBox[customObjectName], location?.pathname)}>Read More {" >>"}</Link></div> : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    ));

    return (
        <div className="customers-inner">
            <div className="custom-row">
                {/* Become our customer box */}
                <div className="customers-box">
                    <div className={isBecomeOurCustomerFlipped ? "flip-card active" : "flip-card"}>
                        <div className="flip-card-inner">
                            <div className="flip-card-front">
                                <img src={isImageInLocalStorage() ? host + getImageInLocalStorage() : "/static/images/client.jpg"} alt="Become Our Customer" onClick={(e) => { handleFlipAndFormSwitch(e, SHOW_BECOME_CUSTOMER_FORM) }} />
                                {isImageInLocalStorage()?(<button type="button" className="clear__btn" onClick={handleBecomeOurCustomerClear}>Clear</button>):(<></>)}
                            </div>
                            <div className="flip-card-back">
                                <div className="flip-card-back-inner">
                                    <h3>{isImageInLocalStorage()? getbBecomeOurCustomerObject().CompanyName : "CompanyName" }</h3>
                                    <div className="flip_txt">
                                        {isImageInLocalStorage()? getbBecomeOurCustomerObject().Description : "Description"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {clientBoxList}
            </div>
            {/* Become our customer pop up */}
            <div className={showRelevantForm === SHOW_BECOME_CUSTOMER_FORM ? "modal fade show" : "modal fade"} id="becomeCustomeModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" style={{ display: showRelevantForm === SHOW_BECOME_CUSTOMER_FORM ? 'block' : 'none' }} aria-hidden="true">
                <div className="modal-dialog become_custome__modal" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Become Our Customer</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => { handleFlipAndFormSwitch(e, SHOW_NO_FORM) }}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <BecomeOurCustomerForm closeForm={closeForm} />
                    </div>
                </div>
            </div>
            <div className="modal-backdrop fade show" style={{ display: showRelevantForm === (SHOW_BECOME_CUSTOMER_FORM) ? 'block' : 'none' }} ></div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(ClientBox)