import React, { useState } from "react";
import { connect } from "react-redux";
import { getFilteredListByIsShow, host } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";
import TeamPopUp from "./TeamPopUp";

interface ITeamBox extends IPageFold { }

const LOAD_MORE_DEFAULT = 8;

const TeamBox = ({ foldData, customObjectName }: ITeamBox) => {

    const [loadMoreLimit, setLoadMoreLimit] = useState(LOAD_MORE_DEFAULT);

    const [manageTeamPopUp, setManageTeamPopUp] = useState({
        isShow: false,
        selectedIndex: -1
    });   

    let manageTeamProps = { manageTeamPopUp, setManageTeamPopUp };

    const handleEmployeeClick = (e: any, index: number) => {
        setManageTeamPopUp({ isShow: true, selectedIndex: index });
    }

    let teamBoxList = getFilteredListByIsShow(foldData).filter((item: any, index: number) => index < loadMoreLimit).map((employee: any, employeeIndex: number) => (
        <div key={employeeIndex} className="team-member-box">
            <div className="team-member--img">
                <img onClick={(e) => { handleEmployeeClick(e, employeeIndex) }} src={host + employee[customObjectName]?.Image1?.url} alt={employee[customObjectName]?.Heading} />
            </div>
            <div className="team-member--content">
                <h3>{employee[customObjectName]?.Heading}</h3>
                <span>{employee[customObjectName]?.SubHeading}</span>
            </div>
        </div>
    ));

    const handleLoadMore = () => {
        setLoadMoreLimit(loadMoreLimit + LOAD_MORE_DEFAULT);
    }

    return (
        <>
            <div className="team-page">
                <section className="team-btm-section">
                    <div className="container">
                        <div className="team-btm--inner">
                            {teamBoxList}
                        </div>
                        {loadMoreLimit < getFilteredListByIsShow(foldData).length ? (<div className="load-more">
                            <button onClick={handleLoadMore} className="primary-btn-outline">Load More</button>
                        </div>) : (<></>)}

                    </div>
                </section>
            </div>
            {manageTeamPopUp?.isShow ? (<TeamPopUp manageTeamProps={manageTeamProps} foldData={foldData} customObjectName={customObjectName} />):(<></>)}           
        </>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(TeamBox)