export const SET_THREE_BLOCKS_SECTION = 'SET_THREE_BLOCKS_SECTION';
export const SET_FOUR_BLOCKS_SECTION = 'SET_FOUR_BLOCKS_SECTION';
export const SET_SLIDER_SECTION = 'SET_SLIDER_SECTION';
export const SET_BIG_PITCURE_SECTION = 'SET_BIG_PITCURE_SECTION';

export const SET_HOME_PAGE_DATA = 'SET_HOME_PAGE_DATA';

export const SET_WEBSITE_DATA = 'SET_WEBSITE_DATA';
export const SET_WEBSITE_METADATA = 'SET_WEBSITE_METADATA';
export const SET_TAG_DATA = 'SET_TAG_DATA';
export const SET_JOB_DATA = 'SET_JOB_DATA';