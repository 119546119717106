import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getFilteredListByIsShow, getUrl, parseHTML } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";

interface IThreeBlockSection extends IPageFold { }

const ThreeBlockSection = ({ foldData, customObjectName }: IThreeBlockSection) => {    
    
    let threeBlockSectionList = getFilteredListByIsShow(foldData).map((elm:any,threeBlockIndex:number)=>(
        <div className="col-sm-4" key={threeBlockIndex}>
            <div className="three_block__in">
                <h3>{elm[customObjectName].Heading}</h3>
                <div className="t_b_txt">
                        {parseHTML(elm[customObjectName]?.Description?.substring(0,214))}
                </div>
                <div className="page_link">                    
                <Link to={getUrl(elm[customObjectName], elm.ParentPageWebsite.PageUrl)}>Read More</Link>
                </div>
            </div>
        </div>));

    return (
        <section className="three-blocks-section">            
            <div className="container">
                <div className="row">
                    {threeBlockSectionList}
                </div>
            </div>
        </section>

    )
}

const mapStateToProps = (state: any) => ({
    threeBlockSection: state.homePage?.homePageData?.ThreeBlockSection
});

const mapActionsToProps = {
    
};

export default connect(mapStateToProps, mapActionsToProps)(ThreeBlockSection)