import React from "react";
import { connect } from "react-redux";
import IPageFold from "../../../utils/IPageFold";
import ClientBox from "./ClientBox";
import ClientHeading from "./ClientHeading";

interface IClientSection extends IPageFold { }

const ClientSection = ({ foldData, customObjectName }: IClientSection) => {    
    return (
        <>
            <section className="customers-section">
                <div className="container">
                    <ClientHeading foldData={foldData?.ClientHeading} customObjectName={customObjectName} />
                    <ClientBox foldData={foldData?.ClientBox} customObjectName={customObjectName} />
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(ClientSection)