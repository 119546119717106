import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getFilteredListByIsShow, getUrl, host, parseHTML } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";

/**
 * This div has 2 divs in it, one for picture in left, one for picture in right.
 */

interface IBigPictureDiv extends IPageFold { }

const BigPictureDiv = ({ foldData, customObjectName }: IBigPictureDiv) => {

    const getRelevantContent = (directContent: any, customObjectConent: any) => {
        try {
            if (directContent && directContent.length > 0)
                return directContent
            return customObjectConent;
        } catch (e) {
            return customObjectConent;
        }
    }

    let bigPictureSectionList = getFilteredListByIsShow(foldData).map((elm: any, index: number) => (
        <div key={index}>
            <section className="big-picture-fold">
                <div className={index % 2 === 0?"container clearfix b-f-left": "container clearfix b-f-right"}>
                    <div className="big-picture">
                        <img src={host + elm[customObjectName]?.Image1?.url} alt="" />
                    </div>
                    <div className="picture-content">
                        <h4>{getRelevantContent(elm?.Heading, elm[customObjectName]?.Heading)}</h4>
                        <div className="home-fold-txt-box">
                            <h5>{parseHTML(getRelevantContent(elm?.SubHeading, elm[customObjectName]?.SubHeading))}</h5>
                            <div className="b_p_txt">
                                {parseHTML(getRelevantContent(elm?.Description, elm[customObjectName]?.Description)?.substring(0, 400))}
                            </div>
                        </div>

                        <div className="page_link">
                            <Link to={getUrl(elm[customObjectName], elm.ParentPageWebsite.PageUrl)}>Read More {" >>"}</Link>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    ));

    return (
        <>
            {bigPictureSectionList}
        </>
    )
}

const mapStateToProps = (state: any) => ({
    bigPictureSection: state.homePage?.homePageData?.BigPictureComponent

});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(BigPictureDiv)