import React from "react";
import { connect } from "react-redux";
import IPageFold from "../../../utils/IPageFold";
import CareerBox from "./CareerBox";

//To do: Add the search functionality and uncomment this search box
//To do: Also add show-hide option in backend so that you can hide and unhide the search bar

interface ICareerSection extends IPageFold { }

const CareerSection = ({ foldData, customObjectName }: ICareerSection) => {

    return (
        <>
            <section className="career-section">
                {foldData?.showSearchBar?(<div className="career-search">
                    <div className="container">
                        <div className="career-search-form">
                            <input type="text" placeholder="Search" />
                            <input type="button" defaultValue="Search" />
                        </div>
                    </div>
                </div>):<></>}
                <div className="career-list-content">
                    <div className="container">
                        {foldData?.CareerHeading?.isShow ? (<h2>{foldData?.CareerHeading?.Heading}</h2>) : (<></>)}
                        <CareerBox foldData={foldData?.CareerBox} customObjectName={customObjectName} />
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(CareerSection)