import { isCharacterSpaceOnly, isEmail, isEmpty, isLength, isNullOrEmpty } from "./CommonUtils";

export const validate = (inputs: any) => {

    const errors = {} as any;

    try {
        if (isEmpty(inputs.name)) {
            errors.name = 'Please enter a name';
        }

        if(inputs.name && !isEmpty(inputs.name) && !isLength(2,30,inputs.name)){
            errors.name = "Name should be between lenth 2 and 30"
        }

        if(inputs.name && !isEmpty(inputs.name) && !isCharacterSpaceOnly(inputs.name)){
            errors.name = "Name should only have letters"
        }

        if (isEmpty(inputs.email)) {
            errors.email = 'Please enter an email address';
        }

        if (inputs.email && isEmail(inputs.email)) {
            errors.email = 'Invalid email address';
        }

        if (isEmpty(inputs.subject)) {
            errors.subject = 'Please enter a subject';
        }

        if (isEmpty(inputs.message)) {
            errors.message = 'Please enter a message';
        }

        return errors;
    } catch (e) {
        console.error(e);
        return {} as any;
    }
}

export const validateOnSubmit = (inputs: any) => {

    const errors = {} as any;

    try {
        if (isNullOrEmpty(inputs.name)) {
            errors.name = 'Please enter a name';
        }

        if (isNullOrEmpty(inputs.email)) {
            errors.email = 'Please enter an email address';
        }

        if (isNullOrEmpty(inputs.subject)) {
            errors.subject = 'Please enter a subject';
        }

        if (isNullOrEmpty(inputs.message)) {
            errors.message = 'Please enter a message';
        }

        return errors;
    } catch (e) {
        console.error(e);
        return {} as any;
    }
}

