import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { getSubUrlFromUrl, getUrl, host, parseHTML } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";
import JoinUsForm from "../Forms/JoinUsForm";


interface ICareerDetail extends IPageFold { }

const CareerDetail = ({ foldData, customObjectName }: ICareerDetail) => {

  const location = useLocation();
  const history = useHistory();

  const [customer, setCustomer] = useState(null as any);
  const [url, setUrl] = useState("");
  const [showJoinUsForm, setShowJoinUsForm] = useState(false);

  const handleJoinUsForm = (e: any) => {
    setShowJoinUsForm(!showJoinUsForm);

    if (!showJoinUsForm) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }

  const closeForm = () => {
    setShowJoinUsForm(false);
    document.body.classList.remove('modal-open');        
}

  useEffect(() => {    
    const getRelevantCustomer = (foldData: any) => {
      let requiredCustomer = foldData.filter((fold: any) => {
        return getUrl(fold[customObjectName], getSubUrlFromUrl(location.pathname)) === location.pathname
      })[0];
      return requiredCustomer;
    }

    const redirectToErrorPage = () => {
      // a hack in case back button is clicked 
      if (location.pathname !== getSubUrlFromUrl(location.pathname)) {
        // history.push("/404");
      }
    }

    if (!customer || location.pathname !== url) {
      let selectedCustomerFromUrl = getRelevantCustomer(foldData);
      if (selectedCustomerFromUrl) {
        setCustomer(selectedCustomerFromUrl);
        setUrl(location.pathname);
      } else {
        setUrl(location.pathname);
        redirectToErrorPage();
      }
    }
  }, [foldData, customer, location.pathname, url, history, customObjectName])

  if (customer && customer[customObjectName])
    return (
      <>
        <section className="career-details-page">
          <div className="career-details-banner">
            <div className="container">
              <h1>{customer[customObjectName]?.Heading}</h1>
            </div>
          </div>
          <div className="career-details-inner">
            <div className="container">
              <div className="career-details-sidebar">
                <div className="career-right-img">
                  <img alt={customer[customObjectName]?.Heading} src={host + customer[customObjectName]?.Image1?.url} />
                </div>
                <h4>{customer[customObjectName]?.Heading}</h4>
                <div className="job-detail-rigth">
                  <ul>
                    <li><i><img alt="mapmarker" src="/static/images/map-marker-alt.svg" /></i>{customer[customObjectName]?.Location}</li>
                    <li><i><img alt="clock" src="/static/images/clock.svg" /></i> {customer[customObjectName]?.RequireExperience}</li>
                  </ul>
                </div>
                <div className="job-short-des">
                  <p>{customer[customObjectName]?.SubHeading}</p>
                </div>
                <div className="apply-btn">
                  <button onClick={handleJoinUsForm} data-toggle="modal" data-target="#joinUs" className="primary-btn-outline">Apply Now</button>
                </div>
              </div>
              <div className="career-details-content">
                <div className="job_des">
                  <h3>Job Description</h3>
                  {parseHTML(customer[customObjectName]?.JobDescription)}
                </div>
                <div className="job_des">
                  <h3>JOB REQUIREMENTS</h3>
                  {parseHTML(customer[customObjectName]?.JobRequirements)}
                </div>
                <div className="job_des">
                  <h3>KEY RESPONSIBILITIES ACTIVITIES</h3>
                  {parseHTML(customer[customObjectName]?.KeyResponsibilities)}
                </div>
                <div className="job_des">
                  <h3>BENEFITS</h3>
                  {parseHTML(customer[customObjectName]?.Benefits)}
                </div>
                <div className="apply-btn">
                  <button onClick={handleJoinUsForm} data-toggle="modal" data-target="#joinUs" className="primary-btn-outline">Apply Now</button>
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
        </section>

        {showJoinUsForm ? (
          <>
            <div className="modal fade show" id="joinUs" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" style={{ paddingRight: '17px', display: 'block' }}>
              <div className="modal-dialog become_custome__modal" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Join Us</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleJoinUsForm}>
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="become_custome__form">
                      <JoinUsForm closeForm={closeForm} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-backdrop fade show" style={{ display: showJoinUsForm ? 'block' : 'none' }} ></div></>
        ) : (<></>)}


      </>
    )
  else
    return (<></>)
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(CareerDetail)