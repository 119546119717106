import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFilteredListByIsShow, getNoSpaceNoSpecialCharacterString, host } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";
import parse from 'html-react-parser';

interface IBigPictureConsulting extends IPageFold { }

interface IBigSectionManage {
    bigSectionIndex: number,
    isReadMore: boolean
}

const BigPictureConsulting = ({ foldData, customObjectName }: IBigPictureConsulting) => {

    const [readMoreManage, setReadMoreManage] = useState([] as Array<IBigSectionManage>);

    const initializeBigSectionArray = useCallback(() => {
        if (readMoreManage.length <= 0) {
            let length = getFilteredListByIsShow(foldData).length;
            let defaultBigSectionArray = [...new Array(length)].map((elm, index) => { return { isReadMore: false, bigSectionIndex: index } });
            setReadMoreManage(defaultBigSectionArray);            
        }
    }, [foldData, readMoreManage, setReadMoreManage]);

    useEffect(initializeBigSectionArray);

    const handleReadMore = (e: any, bigSectionIndex: number) => {
        e.preventDefault();
        readMoreManage[bigSectionIndex] = { ...readMoreManage[bigSectionIndex], isReadMore: !readMoreManage[bigSectionIndex]?.isReadMore }
        setReadMoreManage([...readMoreManage]);
    }

    const checkShowReadMore = (bigSectionIndex: number) => {
        try{
            if(readMoreManage.length>0 && readMoreManage[bigSectionIndex]){
                return readMoreManage[bigSectionIndex].isReadMore;
            }else{
                return false;
            }
        }catch(e){
            console.error(e);
            return false;
        }
        
    }

    let bigPictureSectionList = getFilteredListByIsShow(foldData).map((elm: any, index: number) => (
        <div key={index}>
            <section className="big-picture-fold" id={getNoSpaceNoSpecialCharacterString(elm[customObjectName].Heading)}>
                <div className={index % 2 === 0 ? "container clearfix b-f-left" : "container clearfix b-f-right"}>
                    <div className="big-picture">
                        <img src={host + elm[customObjectName]?.Image1?.url} alt="" />
                    </div>
                    <div className={checkShowReadMore(index)?"picture-content showcontent":"picture-content"}>
                        <h4>{elm[customObjectName]?.Heading}</h4>                        
                        <div className="in-fold-txt-box">
                    <h5>{parse(elm[customObjectName].SubHeading)}</h5>
                    <div className="b_p_txt">                        
                        {checkShowReadMore(index)?parse(elm[customObjectName]?.Description):parse(elm[customObjectName]?.Description.substring(0,300)) }
                    </div>
                    </div>
                    <div className="page_link">
                        <a href="#placeholder" onClick={(e) => { handleReadMore(e, index) }}>{checkShowReadMore(index)?"Show Less":"Read More >>"}</a>
                    </div>
                </div>
                </div>
            </section>
        </div >
    ));

return (
    <>
        {bigPictureSectionList}
    </>
)
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(BigPictureConsulting)