import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getSubUrlFromUrl, getUrl, host, parseHTML } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";
import CustomSlider from "react-slick";
import { Link } from "react-router-dom";

interface IClientDetail extends IPageFold { }

const ClientDetail = ({ foldData, customObjectName }: IClientDetail) => {
    const location = useLocation();
    const history = useHistory();

    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }
   
   const handleTabs = (e: any, tabNumber: number) => {
        e.preventDefault();
        setTab(tabNumber);
    }

    const [customer, setCustomer] = useState(null as any);
    const [tab, setTab] = useState(1);
    const [url, setUrl] = useState("");

    useEffect(() => {        
        const getRelevantCustomer = (foldData: any) => {
            let requiredCustomer = foldData.filter((fold: any) => {
                return getUrl(fold[customObjectName], getSubUrlFromUrl(location.pathname)) === location.pathname
            })[0];
            return requiredCustomer;
        }

        const redirectToErrorPage = () => {
            // a hack in case back button is clicked 
            if (location.pathname !== getSubUrlFromUrl(location.pathname)) {
                // history.push("/404");
            }
        }

        if (!customer || location.pathname !== url) {
            let selectedCustomerFromUrl = getRelevantCustomer(foldData);
            if (selectedCustomerFromUrl) {
                setCustomer(selectedCustomerFromUrl);
                setUrl(location.pathname);
            } else {
                setUrl(location.pathname);
                redirectToErrorPage();
            }
        }
    }, [foldData, customer, location.pathname, url, history, customObjectName])

    let relatedCaseStudies = customer?.RelatedCaseStudy && customer?.RelatedCaseStudy.length > 0 && customer?.RelatedCaseStudy.map((caseStudy: any, caseStudyIndex: number) => (
        <li key={caseStudyIndex}><Link to={getUrl(caseStudy?.RelatedCaseStudy, getSubUrlFromUrl(location.pathname))}><img src={host + caseStudy?.RelatedCaseStudy?.Icon?.url} alt={host + caseStudy?.RelatedCaseStudy?.Heading} /></Link></li>
    ))


    return (
        <>
            <section className="customer-detail-section">
                <div className="container">
                    <h2>Case Study</h2>
                    <div className="customer-detail-inner">
                        <div className="customer-detail-sidebar">
                            <div className="customer-logo">
                                <img src={host + customer?.Customer?.Icon.url} alt={customer?.Customer?.Heading} />
                            </div>
                            <div className="short-details">
                                {parseHTML(customer?.Customer?.SubHeading)}
                            </div>
                            <div className="related-case-study">
                                <h4>Related Case Studies</h4>
                                <ul>
                                    {relatedCaseStudies}
                                </ul>
                            </div>
                        </div>
                        <div className="customer-detail-content">
                            <h1>{customer?.Customer?.Heading}</h1>
                            <div className="c_d_des">
                                {parseHTML(customer?.Customer?.Description)}
                            </div>
                            <div className="case-study-slider">
                                <CustomSlider {...settings}>
                                    <div className="carousel-item">
                                        <img src={host + customer?.Customer?.Image1?.url} alt={customer?.Customer?.Heading} />
                                    </div>
                                    {customer?.Customer?.Image2?.url.length >0 ? (<div className="carousel-item">
                                        <img src={host + customer?.Customer?.Image2?.url} alt={customer?.Customer?.Heading} />
                                    </div>) : null}

                                </CustomSlider>
                            </div>
                            <div className="case-study-tabs">
                                <div className="case-study-tabs--top">
                                    <ul className="nav" id="myTab" role="tablist">
                                        <li>
                                            <button  onClick={(e) => { handleTabs(e, 1) }} className={tab === 1 ? "active" : ""} id="tab-a" >challenge</button>
                                        </li>
                                        <li>
                                            <button  onClick={(e) => { handleTabs(e, 2) }} className={tab === 2 ? "active" : ""} id="tab-b">solutions</button>
                                        </li>
                                        <li>
                                            <button  onClick={(e) => { handleTabs(e, 3) }} className={tab === 3 ? "active" : ""} id="tab-c">result</button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="case-study-tabs--btm tab-content" id="myTabContent">
                                    <div className={tab === 1 ? "tab-pane fade show active" : "tab-pane fade"} id="taba">
                                        {parseHTML(customer?.Customer?.Challenge)}
                                    </div>
                                    <div className={tab === 2 ? "tab-pane fade show active" : "tab-pane fade"} id="tabb">
                                        {parseHTML(customer?.Customer?.Solutions)}
                                    </div>
                                    <div className={tab === 3 ? "tab-pane fade show active" : "tab-pane fade"} id="tabc">
                                        {parseHTML(customer?.Customer?.Results)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="clear" />
                    </div>
                </div>
            </section>

        </>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(ClientDetail)