import React, { useEffect } from "react";
import { connect } from "react-redux";
import JoinUsForm from "../Forms/JoinUsForm";

interface IClaimYourSpacePopUp {
    claimPopUpProps: any
 }

const ClaimYourSpacePopUp = ({ claimPopUpProps}: IClaimYourSpacePopUp) => {

    useEffect(()  => {
        document.body.classList.add('modal-open');
    
        return () => {
            document.body.classList.remove('modal-open');
        };
    });

    const handleClose = ()=>{
        claimPopUpProps.setShowClaimPopUp(false);
    }

    return (
        <div>
        <div className="modal fade show" id="joinUs" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" style={{ display: 'block', paddingRight: '17px' }}>
            <div className="modal-dialog become_custome__modal" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Join Us</h5>
                        <button onClick={handleClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="become_custome__form">
                           <JoinUsForm closeForm={handleClose} />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div className="modal-backdrop fade show"></div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(ClaimYourSpacePopUp)