import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getUrl, getSubUrlFromUrl, host, parseHTML } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";
import CustomSlider from "react-slick";
import JoinUsForm from "../Forms/JoinUsForm";
import BecomeOurCustomerForm from "../Forms/BecomeOurCustomerForm";

interface IBlogDetail extends IPageFold {
    metadata: any
}

/**
 * To do:
 * 1. Add ability to add social links in blog detail section in LmdWebsite (backend)
 * 2. Uncomment the part to show social links
 * 3. 
 * 
 */

const BlogDetail = ({ foldData, customObjectName, metadata }: IBlogDetail) => {

    const location = useLocation();
    const history = useHistory();

    const [blog, setBlog] = useState(null as any);
    const [url, setUrl] = useState("");

    const SHOW_NO_FORM = 0;
    const SHOW_JOIN_US_FORM = 1;
    const SHOW_BECOME_CUSTOMER_FORM = 2;

    const PREVIOUS_URL = -1;
    const NEXT_URL = 1;

    const [showRelevantForm, setShowRelevantForm] = useState(SHOW_NO_FORM);

    const socialLinks = {
        facebook: "https://facebook.com/",
        twitter: "https://twitter.com/",
        pinterest: "https://www.pinterest.com/",
        linkedin: "https://www.linkedin.com/"
    } as any;

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    const getPreviousOrNextUrl = (urlType: number) => {
        try {
            if (foldData && foldData.length > 0) {
                let currentIndex = foldData.indexOf(blog);
                let length = foldData.length;

                if (urlType === PREVIOUS_URL) {
                    let selectedIndex = currentIndex + PREVIOUS_URL >= 0 ? currentIndex + PREVIOUS_URL : length - 1;
                    return getUrl(foldData[selectedIndex][customObjectName], getSubUrlFromUrl(location.pathname))
                }

                if (urlType === NEXT_URL) {
                    let selectedIndex = currentIndex + NEXT_URL < length ? currentIndex + NEXT_URL : 0;
                    return getUrl(foldData[selectedIndex][customObjectName], getSubUrlFromUrl(location.pathname))
                }

                return "/";

            } else {
                return "/"
            }
        } catch (e) {
            return "/"
        }
    }

    useEffect(() => {
        const getRelevantBlog = (foldData: any) => {
            let requiredCustomer = foldData.filter((fold: any) => {
                return getUrl(fold[customObjectName], getSubUrlFromUrl(location.pathname)) === location.pathname
            })[0];
            return requiredCustomer;
        }

        if (!blog || location.pathname !== url) {
            let selectedCustomerFromUrl = getRelevantBlog(foldData);
            if (selectedCustomerFromUrl) {
                setBlog(selectedCustomerFromUrl);
                setUrl(location.pathname);
            } else {
                setUrl(location.pathname);
            }
        }
    }, [foldData, blog, location.pathname, url, history, customObjectName])

    let relatedBlogList = blog?.RelatedBlog && blog?.RelatedBlog.length > 0 && blog?.RelatedBlog.map((blogItem: any, blogIndex: number) => (
        <div key={blogIndex} className="related-post-row clearfix">
            <div className="related-post--img">
                <img src={host + blogItem?.RelatedBlog?.Image1?.url} alt="" />
            </div>
            <div className="related-post--content">
                <Link to={getUrl(blogItem?.RelatedBlog, getSubUrlFromUrl(location.pathname))}>{blogItem?.RelatedBlog?.Heading}</Link>
            </div>
        </div>
    ));

    let sliderComponentList = blog && blog[customObjectName] && blog[customObjectName]?.Image2.map((imageElement: any, imageIndex: number) => (
        <div className="slick-slide" key={imageIndex}>
            <div>
                <div className="blog-image-item">
                    <img src={host + imageElement?.url} width="100%" alt="" />
                </div>
            </div>
        </div>

    ));

    const handleFormSwitch = (e: any, formNumber: number) => {

        setShowRelevantForm(formNumber);

        if (formNumber === SHOW_NO_FORM) {
            document.body.classList.remove('modal-open');
        } else {
            document.body.classList.add('modal-open');
        }
    }

    const closeForm = () => {
        setShowRelevantForm(SHOW_NO_FORM);
        document.body.classList.remove('modal-open');
    }

    if (blog && blog[customObjectName])
        return (
            <>
                <section className="blog-detail-section">
                    <div className="container">
                        <div className="blog-detail-inner">
                            <div className="blog-detail-content">
                                <div className="white__bg">
                                    {blog[customObjectName]?.showSocialMediaLinks ? (<div className="blog-social-media-icon">
                                        <ul>
                                            <li><a href={socialLinks.facebook}><img src="/static/images/facebook-blue.svg" width="100%" alt="" /></a></li>
                                            <li><a href={socialLinks.linkedin}><img src="/static/images/linkedin-blue.svg" width="100%" alt="" /></a></li>
                                        </ul>
                                    </div>) : <></>}
                                    <div className="blog-image-slider">
                                        <CustomSlider {...settings}>
                                            {sliderComponentList}
                                        </CustomSlider>
                                    </div>


                                    <h1>{blog[customObjectName]?.Heading}</h1>
                                    <div className="blog-auther-date">
                                        <ul>
                                            <li><i><img src="/static/images/user.svg" alt="" /></i>{blog[customObjectName]?.Author}</li>
                                            <li><i><img src="/static/images/calendar-alt.svg" alt="" /></i>15 April, 2021</li>
                                        </ul>
                                    </div>
                                    <div className="blog-content">
                                        {parseHTML(blog[customObjectName]?.Description)}
                                    </div>
                                    {blog[customObjectName]?.showSocialMediaLinks ? (<div className="share-blog">
                                        <ul>
                                            <li><a href={socialLinks.facebook}><img src="/static/images/facebook-blue.svg" width="100%" alt="" /></a></li>
                                            <li><a href={socialLinks.linkedin}><img src="/static/images/linkedin-blue.svg" width="100%" alt="" /></a></li>
                                            <li><a href={socialLinks.pinterest}><img src="/static/images/pinterest-blue.svg" width="100%" alt="" /></a></li>
                                            <li><a href={socialLinks.twitter}><img src="/static/images/twitter-blue.svg" width="100%" alt="" /></a></li>
                                        </ul>
                                    </div>) : <></>}
                                </div>
                                <div className="next-pre-blog clearfix">
                                    <ul>
                                        <li className="previous-blog">
                                            <Link to={getPreviousOrNextUrl(PREVIOUS_URL)}>
                                                <i /> Previous Blog
              </Link>
                                        </li>
                                        <li className="next-blog">
                                            <Link to={getPreviousOrNextUrl(NEXT_URL)}>
                                                Next Blog <i />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="blog-detail-sidebar">
                                <div className="related-post">
                                    <h3>Related Posts</h3>
                                    <div className="related-post-list">
                                        {relatedBlogList}
                                    </div>
                                </div>
                                <div className="sidebar-btn-set mt-15">
                                    <button onClick={(e) => { handleFormSwitch(e, SHOW_BECOME_CUSTOMER_FORM) }} className="primary-btn-outline" data-toggle="modal" data-target="#becomeCustomeModal">Become Our
            Customer</button>
                                    <button onClick={(e) => { handleFormSwitch(e, SHOW_JOIN_US_FORM) }} className="primary-btn-outline" data-toggle="modal" data-target="#joinUs">Join Us</button>
                                </div>
                            </div>
                            <div className="clear" />
                        </div>
                    </div>
                </section>

                <div>
                    <div className={showRelevantForm === SHOW_JOIN_US_FORM ? "modal fade show" : "modal fade"} id="joinUs" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" style={{ display: showRelevantForm === SHOW_JOIN_US_FORM ? 'block' : 'none' }} aria-hidden="true">
                        <div className="modal-dialog become_custome__modal" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Join Us</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => { handleFormSwitch(e, SHOW_NO_FORM) }}>
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="become_custome__form">
                                        <JoinUsForm closeForm={closeForm} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div>

                        <div className={showRelevantForm === SHOW_BECOME_CUSTOMER_FORM ? "modal fade show" : "modal fade"} id="becomeCustomeModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" style={{ display: showRelevantForm === SHOW_BECOME_CUSTOMER_FORM ? 'block' : 'none' }} aria-hidden="true">
                            <div className="modal-dialog become_custome__modal" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Become Our Customer</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={(e) => { handleFormSwitch(e, SHOW_NO_FORM) }}>
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <BecomeOurCustomerForm closeForm={closeForm} />
                                </div>
                            </div>

                        </div>


                        <div className="modal-backdrop fade show" style={{ display: showRelevantForm === (SHOW_JOIN_US_FORM) || showRelevantForm === (SHOW_BECOME_CUSTOMER_FORM) ? 'block' : 'none' }} ></div>
                    </div>

                </div>

            </>
        )
    else
        return (<></>)
}

const mapStateToProps = (state: any) => ({
    metadata: state.website.metadata
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(BlogDetail)