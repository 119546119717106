import React from 'react'
import { Link } from 'react-router-dom';
import { getFilteredListByIsShow, getUrl, host } from '../utils/commonComponentUtils';

interface IFooter {
    foldData: any
}

const ArticleCollectionName="Article"

export const Footer = ({ foldData }: IFooter) => {

    let footerLinksList = getFilteredListByIsShow(foldData?.FooterLinks).map((footerObject: any, footerObjectIndex: number) => {
        return (<li key={footerObjectIndex}><Link to={getUrl(footerObject[ArticleCollectionName],footerObject.ParentPageUrl.PageUrl)}>{footerObject.LinkText}</Link></li>)
    })

    let socialLinksList = getFilteredListByIsShow(foldData?.SocialLinks).map((socialLinkObject:any,socialLinkIndex:number)=>{
        return(<li key={socialLinkIndex}><Link to={{ pathname: socialLinkObject?.Url }} target="_blank"><img src={host+socialLinkObject?.Icon?.url} alt={socialLinkObject?.Name} /></Link></li>)
    })

    const getCurrentYear = ()=>{        
        return new Date().getFullYear();
    }

    return (
        <footer className="lmd-footer">
            <div className="container-fluid">
                <div className="custom-row">
                    <div className="footer-links">
                        <ul>
                            {footerLinksList}
                        </ul>
                    </div>
                    <div className="social-links">
                        <ul>
                            {socialLinksList}
                        </ul>
                    </div>
                    <div className="copyright-text">
                        {getCurrentYear()} {foldData?.CopyRightText}
      </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer;


