// UI
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// API responces 
export const STATUSOKAY = "OKAY";
export type STATUSOKAY = typeof STATUSOKAY;
export const STATUSERROR = "ERROR";
export type STATUSERROR = typeof STATUSERROR;


export const COOKIE_NAME = process.env.REACT_APP_AUTH_COOKIE_NAME || 'accessToken'


export const SHOW_DATE_FORMET = "MM/DD/YYYY";
export const SHOW_DATE_TIME_FORMET = "MM/DD/YYYY hh:mm:ss a";