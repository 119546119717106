import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import { getFoldOrderReduced } from '../utils/commonComponentUtils';
import { reactStrapiComponentMapping } from '../utils/reactStrapiComponentMapping';

/**
 * This page is main landing page. This will take care of entirety of requests.  
 */

interface IHomePage {
    pages: any,
    metadata: any    
}

const HomePage = ({ pages, metadata }: IHomePage
) => {
    let location = useLocation();    
    const [reactElementList, setReactElementList] = useState([] as any);
    const [currentUrl, setCurrentUrl] = useState("");

    const initiatePage = useCallback(() => {

        const isMetadata = () => {
            return metadata && metadata?.LandingPage;
        }

        const isPagesLoaded = () => {
            return (pages && pages.length > 0);
        }

        const isPageUrlChanged = () => {            
            return currentUrl !== location.pathname;
        }

        const isPageDynamic = () => {
            const pathName = location.pathname;
            const pageDataByUrl = pages.filter((page: any) => page.isDynamic)?.filter((page: any) => pathName.includes(page?.ParentPage?.PageUrl + "/") && pathName !== page?.ParentPage?.PageUrl)[0];
            const isDynamic = pageDataByUrl ? true : false;
            return isDynamic;
        }

        const isPageStatic = () => {
            const pathName = location.pathname;
            const pageDataByUrl = pages.filter((page: any) => page.PageUrl === pathName)[0];
            const isStatic = pageDataByUrl ? true : false;
            return isStatic;
        }

        const isDefaultPage = () => {
            const pathName = location.pathname;
            return (pathName === '/');
        }

        const getFoldData = (foldName: string, pageData: any) => {
            const folds = pageData.PageComponent[0];
            let foldData: any;

            for (const foldKey in folds) {
                if (foldName === foldKey) {
                    foldData = folds[foldKey];
                }
            }
            return foldData;
        }

        const getLandingPageData = (metadata: any) => {
            const landingPageId = metadata.LandingPage._id;
            const landingPageData = pages.filter((page: any) => page._id === landingPageId)[0];
            return landingPageData;
        }

        const getDynamicPageData = () => {
            const pathName = location.pathname;
            const pageDataByUrl = pages.filter((page: any) => page.isDynamic)?.filter((page: any) => pathName.includes(page?.ParentPage?.PageUrl + "/") && pathName !== page?.ParentPage?.PageUrl)[0];
            return pageDataByUrl;
        }
        
        const getStaticPageData = () => {
            const pathName = location.pathname;
            let pageDataByUrl = pages.filter((page: any) => page.PageUrl === pathName)[0];
            return pageDataByUrl;
        }

        const getPageDataByUrl = () => {
            let pageData;
            if (isDefaultPage()) {
                pageData = getLandingPageData(metadata);
            } else if (isPageDynamic()) {
                pageData = getDynamicPageData();                
            } else if (isPageStatic()) {
                pageData = getStaticPageData();                
            } else {
                //To do: Create a 404 Page in Backend and load it from there. 
                console.log('404 page error');
                pageData = getLandingPageData(metadata);
            }
            return pageData;
        }

        const createReactElements = (foldOrderReduced: any, pageData: any) => {
            const reactElementListTemp = [] as any[];

            foldOrderReduced.forEach((foldOrderElement: any, foldOrderIndex: number) => {
                let foldData = getFoldData(foldOrderElement.foldName, pageData);
                reactElementListTemp.push(
                    React.createElement(reactStrapiComponentMapping[foldOrderElement.foldType],
                        { key: foldOrderIndex, foldData, customObjectName: foldOrderElement.customObjectName }
                    ));
            })

            return reactElementListTemp;
        }

        const mapComponentsToPageByPageData = (pageData: any) => {
            const foldOrderReduced = getFoldOrderReduced(pageData);
            const reactElementListTemp = createReactElements(foldOrderReduced, pageData);
            setReactElementList(reactElementListTemp);
        }

        const loadThePage = () => {
            if (isMetadata() && isPagesLoaded()) {
                if (isPageUrlChanged()) {
                    // console.log('inside ismetadata block');
                    const pageData = getPageDataByUrl();
                    mapComponentsToPageByPageData(pageData);
                    setCurrentUrl(location.pathname);
                    window.scrollTo(0, 0);
                } else {
                    // console.log("Page url didn't change so do nothing");
                }
            } else {
                //Todo: If no metadata received show a default static page
            }
        }

        loadThePage();
    }, [pages, metadata, currentUrl, location])

    useEffect(() => { initiatePage(); }, [initiatePage]);

    return (
            <div className="mid-container">
                {reactElementList}
            </div>
    );
}


const mapStateToProps = (state: any) => ({
    user: state.user,
    UI: state.UI,
    pages: state.website.pages,
    metadata: state.website.metadata
});

const mapActionsToProps = {    
};

export default connect(mapStateToProps, mapActionsToProps)(HomePage)