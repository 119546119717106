import { isCharacterSpaceOnly, isEmail, isEmpty, isLength, isNullOrEmpty } from "./CommonUtils";

export const validate = (inputs: any) => {

    const errors = {} as any;

    try {
        if (isEmpty(inputs.name)) {
            errors.name = 'Please enter a name';
        }

        if(inputs.name && !isEmpty(inputs.name) && !isLength(2,30,inputs.name)){
            errors.name = "Name should be between lenth 2 and 30"
        }

        if(inputs.name && !isEmpty(inputs.name) && !isCharacterSpaceOnly(inputs.name)){
            errors.name = "Name should only have letters"
        }

        if (isEmpty(inputs.companyName)) {
            errors.companyName = 'Please enter a company name';
        }

        if(inputs.companyName && !isEmpty(inputs.companyName) && !isLength(2,30,inputs.companyName)){
            errors.companyName = "Company Name should be between lenth 2 and 30"
        }


        if (isEmpty(inputs.email)) {
            errors.email = 'Please enter an email address';
        }

        if (inputs.email && isEmail(inputs.email)) {
            errors.email = 'Invalid email address';
        }

        if (inputs.phone && inputs.phone !== "" && inputs.phone.length < 10) {
            errors.phone = 'Phone number must be at least 10 digits';
        }

        return errors;
    } catch (e) {
        console.error(e);
        return {} as any;
    }
}

export const validateOnSubmit = (inputs: any) => {

    const errors = {} as any;

    try {
        if (isNullOrEmpty(inputs.name)) {
            errors.name = 'Please enter a name';
        }

        if (isNullOrEmpty(inputs.companyName)) {
            errors.companyName = 'Please enter a company name';
        }

        if (isNullOrEmpty(inputs.email)) {
            errors.email = 'Please enter an email address';
        }

        return errors;
    } catch (e) {
        console.error(e);
        return {} as any;
    }
}

