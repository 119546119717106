
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';


export type SET_ERRORS = typeof SET_ERRORS;
export type LOADING_UI = typeof LOADING_UI;
export type CLEAR_ERRORS = typeof CLEAR_ERRORS;


export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE'
