import Banner from "../components/PageFolds/Consulting/Banner";
import BigPicture from "../components/PageFolds/HomePage/BigPicture";
import BigPictureConsulting from "../components/PageFolds/Consulting/BigPictureConsulting";
import FourBlockSection from "../components/PageFolds/HomePage/FourBlockSection";
import Slider from "../components/PageFolds/HomePage/Slider";
import ThreeBlockSection from "../components/PageFolds/HomePage/ThreeBlockSection";
import { ARTICLE_DETAIL_COMPONENT, BANNER_COMPONENT, BIG_PICTURE_COMPONENT, BIG_PICTURE_CONSULTING_COMPONENT, BIG_PICTURE_INDUSTRY_COMPONENT, BLOG_COMPONENT, BLOG_DETAIL, CAREER_DETAIL_COMPONENT, CAREER_MAJOR_COMPONENT, CLIENT_DETAIL_COMPONENT, CLIENT_MAJOR_COMPONENT, CONTACT_FORM_COMPONENT, FOOTER_COMPONENT, FOUR_BLOCK_COMPONENT, HEADER_COMPONENT, LOCATION_MAP_COMPONENT, PAGE_SEARCH_COMPONENT, SLIDER_COMPONENT, TABS_INDUSTRY_COMPONENT, TAB_COMPONENT, TEAM_BANNER, TEAM_BOX, THREE_BLOCK_COMPONENT } from "../constants/strapiFoldTypes";
import Tabs from "../components/PageFolds/Consulting/Tabs";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ClientSection from "../components/PageFolds/Customer/ClientSection";
import ClientDetail from "../components/PageFolds/Customer/ClientDetail";
import ArticleDetail from "../components/PageFolds/Article/ArticleDetail";
import CareerSection from "../components/PageFolds/Career/CareerSection";
import CareerDetail from "../components/PageFolds/Career/CareerDetail";
import Blog from "../components/PageFolds/Blog/Blog";
import BlogDetail from "../components/PageFolds/Blog/BlogDetail";
import TeamBox from "../components/PageFolds/Team/TeamBox";
import TeamHeader from "../components/PageFolds/Team/TeamHeader";
import ContactFormSection from "../components/PageFolds/AboutUs/ContactFormSection";
import LocationMap from "../components/PageFolds/AboutUs/LocationMap";
import PageSearchComponent from "../components/PageFolds/Search/PageSearchComponent";

export const reactStrapiComponentMapping: any = {
    [ARTICLE_DETAIL_COMPONENT]:ArticleDetail,
    [BANNER_COMPONENT]: Banner,
    [BIG_PICTURE_COMPONENT]: BigPicture,
    [BIG_PICTURE_CONSULTING_COMPONENT]: BigPictureConsulting,
    [BIG_PICTURE_INDUSTRY_COMPONENT]: BigPictureConsulting,
    [BLOG_COMPONENT]:Blog,
    [BLOG_DETAIL]:BlogDetail,
    [CAREER_DETAIL_COMPONENT]:CareerDetail,
    [CAREER_MAJOR_COMPONENT]:CareerSection,
    [CLIENT_DETAIL_COMPONENT]:ClientDetail,
    [CLIENT_MAJOR_COMPONENT]:ClientSection,
    [CONTACT_FORM_COMPONENT]:ContactFormSection,
    [FOOTER_COMPONENT]: Footer,
    [FOUR_BLOCK_COMPONENT]: FourBlockSection,
    [HEADER_COMPONENT]: Header,
    [LOCATION_MAP_COMPONENT]:LocationMap,
    [PAGE_SEARCH_COMPONENT]:PageSearchComponent,
    [SLIDER_COMPONENT]: Slider,
    [TAB_COMPONENT]: Tabs,
    [TABS_INDUSTRY_COMPONENT]: Tabs,
    [TEAM_BANNER]:TeamHeader,
    [TEAM_BOX]:TeamBox,
    [THREE_BLOCK_COMPONENT]: ThreeBlockSection   
}