import React from "react";
import { connect } from "react-redux";
import { host } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";

/**
 * This banner is getting reused in multiple places like in Customer Page.
 */

interface IBanner extends IPageFold { }

const Banner = ({ foldData, customObjectName }: IBanner) => {
    return (
        <>
            <section className="page-banner-section" style={{ backgroundImage: 'url(' + host + foldData[customObjectName]?.Image1?.url + ')' }}>
                <div className="container">
                    <h1>{foldData[customObjectName].Heading}</h1>
                </div>
            </section>
        </>

    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(Banner)