import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getFilteredListByIsShow, getNoSpaceNoSpecialCharacterString } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";



/**
 * For tabs on consulting page
 */


interface ITabs extends IPageFold { }

const TABS_TO_SHOW = 4;

const Tabs = ({ foldData, customObjectName }: ITabs) => {    

    //Hooks
    const [scrollManageClass,setScrollManageClass] = useState("fold-menu-section");

    // Handle Functions
    const handleLinkClick = (e: any, idToHighlight: string) => {
        e.preventDefault();
        const anchor: any = document.querySelector(idToHighlight)
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    const handleScroll = (e: any) => {            
        if(window.scrollY>=200){
            setScrollManageClass("fold-menu-section tabsfixed");
        }else{
            setScrollManageClass("fold-menu-section");
        }
    }

    //useEffect Functions
    useEffect(()=>{        
        window.addEventListener('scroll',handleScroll);
        return(()=>{window.removeEventListener('scroll',handleScroll)})
    },[])

    //Lists:
    let tabList = getFilteredListByIsShow(foldData).map((tabElement: any, tabIndex: number) => (
        <li key={tabIndex}><a onClick={(e) => { handleLinkClick(e, '#' + getNoSpaceNoSpecialCharacterString(tabElement[customObjectName].Heading)); }} href={'#' + getNoSpaceNoSpecialCharacterString(tabElement[customObjectName].Heading)}>{tabElement[customObjectName].Heading}</a></li>
    ));

    //Divs:
    const ButtonDiv = () => {

        const isShowButton = () => {
            try {
                if (tabList && (tabList.length > TABS_TO_SHOW))
                    return true;
                else
                    return false;
            } catch (e) {
                return false;
            }
        }

        if (isShowButton())
            return (
                <>
                    <div className="fold_menu_receiver">
                        <ul id="receiver" />
                        <div className="menu_arrow">
                            <button id="receiverClick"><img src="static/images/angle-down-white.svg" alt="" /></button>
                        </div>
                    </div>
                </>)
        else
            return <></>
    }

    return (
        <>
            <section id="tabsFixed" className={scrollManageClass}>
                <div className="container">
                    <div className="custom-row">
                        <div className="fold_menu">
                            <ul id="teleporter">
                                {tabList}
                            </ul>
                        </div>
                        <ButtonDiv />
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(Tabs)