import React from "react";
import { connect } from "react-redux";
import { host } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";

interface ILocationMap extends IPageFold { }

const LocationMap = ({ foldData, customObjectName }: ILocationMap) => {
    
    return (
        <section className="our-location-section">
            <div className="container">
                <h2>Our Locations</h2>
                <div className="location-map">
                    <img src={host + foldData?.Image1?.url} alt={foldData?.Image1?.alternativeText} className="world-map" />
                    <div className="address-bg usa">
                        <div className="address">
                            <ul>
                                <li><i className="fa fa-home" /> <span>{foldData?.Location1?.Location}</span></li>
                                <li><i className="fa fa-phone" /> <span>{foldData?.Location1?.Phone}</span></li>
                                <li><i className="fa fa-envelope" /> <span><a href={"mailto:" + foldData?.Location1?.Email}>{foldData?.Location1?.Email}</a></span></li>
                            </ul>
                            <img src="/static/images/location-icon.png" alt="location-icon" className="map-icon" />
                        </div>
                    </div>
                    <div className="address-bg india">
                        <div className="address">
                            <ul>
                                <li><i className="fa fa-home" /> <span>{foldData?.Location2?.Location}</span></li>
                                <li><i className="fa fa-phone" /> <span>{foldData?.Location2.Phone}</span></li>
                                <li><i className="fa fa-envelope" /> <span><a href={"mailto:" + foldData?.Location2?.Email}>{foldData?.Location2?.Email}</a></span></li>
                            </ul>
                            <img src="static/images/location-icon.png" alt="location-icon" className="map-icon" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}

const mapStateToProps = (state: any) => ({
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(LocationMap)