import React from "react";
import { connect } from "react-redux";
import { getFilteredListByIsShow, getUrl, host, parseHTML } from "../../../utils/commonComponentUtils";
import IPageFold from "../../../utils/IPageFold";
import CustomSlider from "react-slick";
import { Link, useLocation } from "react-router-dom";

interface IBlogHeader extends IPageFold {
    tags: Map<string, string>
}
const HEADER_CHAR_LIMIT = 100;

const BlogHeader = ({ foldData, customObjectName,tags }: IBlogHeader) => {
    let location = useLocation();

    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [

            {
                breakpoint: 567,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false
                }
            }

        ]
    }

    let sliderComponentList = getFilteredListByIsShow(foldData).map((blogElement: any, blogIndex: number) => (
        <div className="slick-slide" key={blogIndex}>
            <div className="blog-items">
                <div className="blog-items--inner">
                    <div className="blog-items--img">
                        <img src={host + blogElement[customObjectName]?.Image1?.url} alt="slider" />
                    </div>
                    <div className="blog-items--content">                        
                                              
                        <h2><Link to={getUrl(blogElement[customObjectName], location?.pathname)}>{blogElement[customObjectName]?.Heading}</Link> </h2>
                        {blogElement[customObjectName]?.Tags?.map((tagId:string,tagIndex:number)=><div key={tagIndex} className="blog-tag-name">{tags.get(tagId)}</div>)}
                        <div className="blog-items--dec">{parseHTML(blogElement[customObjectName]?.SubHeading?.substr(0, HEADER_CHAR_LIMIT))}</div>
                    </div>
                </div>
            </div>
        </div>
    ));

    return (

        <section className="blog-top-section hero-section" style={{ backgroundImage: 'url(static/images/blog-banner.jpg)' }}>
            <div className="container">
                <h1>Blog</h1>
                <div className="blog-list-slider">
                    <CustomSlider {...settings}>
                        {sliderComponentList}
                    </CustomSlider>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = (state: any) => ({
    tags: state.website.tags
});

const mapActionsToProps = {
};

export default connect(mapStateToProps, mapActionsToProps)(BlogHeader)