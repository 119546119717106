import parse from 'html-react-parser';
import slugify from 'react-slugify';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';

export const host = process.env.REACT_APP_API_URL

export const getNoSpaceNoSpecialCharacterString = (myString: string) => {
    return myString.replace(/[^A-Z0-9]+/ig, "");
}

export const getFilteredListByIsShow = (list: any) => {
    try {
        return (list && list.length > 0) ? list.filter((elm: any) => elm.isShow) : [];
    } catch (e) {
        console.error(e);
        return [];
    }
}

const getAutomaticUrl = (clientObject: any) => {
    try {
        return slugify(clientObject?.Heading);
    }
    catch (e) {
        return "base-url";
    }
}

export const getUrl = (clientObject: any, subDirectory: any) => {
    let customUrl = clientObject?.Url ? clientObject?.Url : null;
    let urlSubDirectory = customUrl ? customUrl : getAutomaticUrl(clientObject);
    return subDirectory + "/" + urlSubDirectory;
}

export const parseHTML = (htmlString: any) => {
    try {
        if (typeof htmlString === 'string')
            return parse(htmlString);
        else
            return htmlString;
    } catch (e) {
        return htmlString;
    }
}

export const getFullUrlFromHeading = (heading: any, parentUrl: any) => {
    try {
        let subUrl = slugify(heading);
        return parentUrl + "/" + subUrl;
    }
    catch (e) {
        console.error("Something went wrong in url creation");
        return "/404";
    }
}

export const getSubUrlFromUrl = (url: string) => {
    return "/" + url.split("/")[1]
}

export const getDaysFromNow = (date: string) => {
    try {
        return formatDistanceToNowStrict(new Date(date), { addSuffix: true });
    } catch (e) {
        return ""
    }
}

export const getFoldOrderReduced = (landingPageData: any) => {
    const foldOrder = landingPageData.FoldOrder;
    const foldOrderReduced = foldOrder.map((foldOrderElement: any) => {
        return {
            foldName: foldOrderElement.FoldName,
            foldType: foldOrderElement.FoldType.Name,
            customObjectName: foldOrderElement.FoldType.CustomObjectName
        }
    });
    return foldOrderReduced;
}