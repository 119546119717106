
import React from "react";


const ErrorMessage = ({ message }: any) => {
    
        return (
            <div className="error-message"> {message}</div>
        )
}

export default (ErrorMessage)